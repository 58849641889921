import { StyleSheet } from 'react-native';

import { colors } from '@emjoy/shared/styles';

const DesktopQrDownloadStyles = StyleSheet.create({
    qrWrapperStyle: {
        width: 304,
        height: 296,
        borderRadius: 8,
        shadowColor: colors.neutral.c400,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowRadius: 24,
        overflow: 'hidden'
    }
});

export { DesktopQrDownloadStyles };
