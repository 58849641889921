import React from 'react';

import { Box } from '@mobily/stacks';

import { EmText } from '../EmText/EmText';
import { EmIcon } from '../EmIcon/EmIcon';

import { EmTextIconProps } from './EmTextIcon.types';

function EmTextIcon(props: EmTextIconProps) {
    const { children, iconLeft, iconRight, iconSize, iconColor, iconSpace, emTextProps, boxProps } = props;

    return (
        <Box direction={'row'} alignY={'center'} {...boxProps}>
            {iconLeft && (
                <EmIcon
                    name={iconLeft}
                    size={iconSize}
                    color={iconColor}
                    boxProps={children && { paddingEnd: iconSpace }}
                />
            )}
            <EmText {...emTextProps}>{children}</EmText>
            {iconRight && (
                <EmIcon
                    name={iconRight}
                    size={iconSize}
                    color={iconColor}
                    boxProps={children && { paddingStart: iconSpace }}
                />
            )}
        </Box>
    );
}
export { EmTextIcon };
