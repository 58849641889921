import { Platform, StyleSheet } from 'react-native';

import { fontSizes, lineHeights, scale, textFonts } from '@emjoy/shared/styles';
import { EmTextProps } from './EmText.types';

const isWeb = Platform.OS === 'web';

const styles = (props: EmTextProps) => {
    const textFont = textFonts[props.weight || 'semibold'];
    return StyleSheet.create({
        text: {
            ...textFont,
            textAlign: props.textAlign,
            flex: Platform.OS === 'web' ? 1 : null,
            textAlignVertical: 'center',
            includeFontPadding: false,
            ...(props.textTransform && { textTransform: props.textTransform })
        },
        huge: {
            fontSize: fontSizes.huge,
            lineHeight: lineHeights.huge,
            paddingTop: isWeb ? null : 2
        },
        big: {
            fontSize: fontSizes.big,
            lineHeight: lineHeights.big,
            paddingTop: isWeb ? null : 1
        },
        extraHuge: {
            fontSize: 64 * scale,
            lineHeight: 80 * scale
        },
        h1: {
            fontSize: fontSizes.h1,
            lineHeight: lineHeights.h1,
            paddingTop: isWeb ? null : 3
        },
        h2: {
            fontSize: fontSizes.h2,
            lineHeight: lineHeights.h2,
            paddingTop: isWeb ? null : 2
        },
        h3: {
            fontSize: fontSizes.h3,
            lineHeight: lineHeights.h3,
            paddingTop: isWeb ? null : 2
        },
        h4: {
            fontSize: fontSizes.h4,
            lineHeight: lineHeights.h4,
            paddingTop: isWeb ? null : 3
        },
        h5: {
            fontSize: fontSizes.h5,
            lineHeight: lineHeights.h5,
            paddingTop: isWeb ? null : 1
        },
        h6: {
            fontSize: fontSizes.h6,
            lineHeight: lineHeights.h6,
            paddingTop: isWeb ? null : 1
        },
        large: {
            fontSize: 18 * scale,
            lineHeight: 23 * scale,
            paddingTop: isWeb ? null : 2
        },
        normal: {
            fontSize: fontSizes.normal,
            lineHeight: lineHeights.normal,
            paddingTop: isWeb ? null : 2
        },
        small: {
            fontSize: fontSizes.small,
            lineHeight: lineHeights.small,
            paddingTop: isWeb ? null : 2
        },
        caption: {
            fontSize: fontSizes.caption,
            lineHeight: lineHeights.caption,
            paddingTop: isWeb ? null : 2
        },
        number: {
            fontSize: 14 * scale,
            lineHeight: 20 * scale,
            letterSpacing: 0.5
        },
        // for the moment icon only scales to 'h4', 'h6' and 'small', let's see different uses in the future
        // we don't apply camelCase because is "icon + type"
        iconh4: {
            width: 24 * scale,
            height: 24 * scale
        },
        iconh6: {
            width: 12 * scale,
            height: 12 * scale
        },
        iconsmall: {
            width: 12 * scale,
            height: 12 * scale
        }
    });
};
export default styles;
