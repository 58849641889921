import { colors } from '@emjoy/shared/styles';
import { StyleSheet } from 'react-native';

// This values need to be absolutes to match each other
const styles = StyleSheet.create({
    answerIconLetter: { marginTop: -2 }, // Typo Issues
    buttonBox: {
        width: '100%',
        borderColor: colors.neutral.c100,
        borderWidth: 1,
        borderRadius: 24,
        overflow: 'hidden'
    },
    buttonFiller: { height: 100, position: 'absolute', top: -18, left: -8 },
    buttonRadioCircle: {
        borderColor: colors.neutral.c400,
        borderWidth: 1,
        width: 30,
        height: 30,
        borderRadius: 30
    },
    buttonRadioCircleInner: {
        width: 30,
        height: 30,
        borderRadius: 15
    },
    buttonGroupContainer: { width: '100%' }
});

export { styles };
