/* eslint-disable */
import 'firebase/auth';
import firebase from 'firebase/app';
import {
    appAndroidBundle,
    appIosBundle,
    signInEmailAndroidMinVersion,
    signInEmailDynamicLinkDomain,
    signInEmailFallbackUrl
} from '../configs/conf';

const googleSignIn = async () => {
    let provider = new firebase.auth.GoogleAuthProvider();

    try {
        const res = await firebase.auth().signInWithPopup(provider);
        return res;
    } catch (error) {
        return { error };
    }
};

const facebookSignIn = async () => {
    let provider = new firebase.auth.FacebookAuthProvider();

    try {
        const res = await firebase.auth().signInWithPopup(provider);
        return res;
    } catch (error) {
        return { error };
    }
};

const emailLogIn = async ({ email, password }) => {
    try {
        return await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
        return { error };
    }
};

const emailSignIn = async ({ name, email, password }) => {
    try {
        // userStore.tempDisplayName = name; //TODO  do we use this?
        return await firebase.auth().createUserWithEmailAndPassword(email, password);
    } catch (error) {
        return { error };
    }
};

const getIdToken = async () => {
    return await firebase.auth().currentUser?.getIdToken();
};

const resetEmailPassword = async userEmail => {
    let result;
    try {
        result = await firebase.auth().sendPasswordResetEmail(userEmail);
    } catch (error) {
        result = { error };
    }

    return result;
};

const sendSignInLink = async email => {
    const actionCodeSettings = {
        handleCodeInApp: true,
        // URL must be whitelisted in the Firebase Console.
        url: signInEmailFallbackUrl, // Fallback URL in case cant open app
        dynamicLinkDomain: signInEmailDynamicLinkDomain,
        iOS: {
            bundleId: appIosBundle
        },
        android: {
            packageName: appAndroidBundle,
            installApp: true,
            minimumVersion: signInEmailAndroidMinVersion
        }
    };

    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);

    //console.log(`Login link sent to ${email}`);
};

// export { appleSignIn, googleSignIn, facebookSignIn, emailSignIn, emailLogIn, getIdToken, resetEmailPassword };
export { emailSignIn, googleSignIn, emailLogIn, getIdToken, resetEmailPassword, facebookSignIn, sendSignInLink };
