/* eslint-disable */
import React, { Component } from 'react';
import { Box } from '@mobily/stacks';

import _ from 'lodash';
import { makeObservable, observable, computed } from 'mobx';
import { observer } from 'mobx-react';

import { GoalItemRow } from 'components/goals';
import { L } from 'libs/i18n';
import { trackEvent } from '@libs/analytics';

import { appStore } from 'stores';
import { withFeatureFlag } from 'src/libs/featureFlags';

// import { goals } from '../../../dataMockup/goals.json'; //TODO use for mocking, comment when not used
//TODO update this file with last goals from admin

//TODO to functional
// const withFeatureFlag = () => {
//     return true;
// };
@observer
class GoalsSelector extends Component {
    @observable
    selectedGoals = this.props.selectedGoals || {};

    onPress = id => {
        if (withFeatureFlag({ id: 'randomGoals' })) {
            if (_.size(this.activeSelectedGoals) < 4 || this.activeSelectedGoals[id]) {
                if (!this.selectedGoals[id]) {
                    const goalListIndex = _.findIndex(appStore.shuffledGoals, {
                        id: id
                    });
                    this.selectedGoals[id] = goalListIndex + 1;
                } else {
                    this.selectedGoals[id] = !this.selectedGoals[id];
                }
            } else {
                this.props.navigation.navigate('Alert', {
                    type: 'error',
                    title: L('ooops'),
                    message: L('goals_limit'),
                    okButton: L('ok'),
                    onOk: () => {
                        this.busy = false;
                    }
                });
                trackEvent('goalsLimitAlert', {
                    location: 'goalsSelector'
                });
            }
        } else {
            this.selectedGoals[id] = !this.selectedGoals[id];
        }
    };

    @computed get activeSelectedGoals() {
        const numberOfSelectedGoals = _.chain(this.selectedGoals).pickBy().value();
        return numberOfSelectedGoals;
    }

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    render() {
        const sortedGoals = _.chain(
            withFeatureFlag({ id: 'randomGoals' }) ? appStore.shuffledGoals : appStore.sortedGoals
        )
            .filter('id')
            .sortBy('order')
            .map(item => {
                return (
                    <GoalItemRow
                        key={`${item.id}`}
                        {...item}
                        onPress={() => this.onPress(item.id)}
                        selected={this.selectedGoals[item.id]}
                        a11yState={true}
                    />
                );
            })
            .value();

        return (
            <Box alignX={'center'} paddingX={2} {...this.props.boxProps}>
                {sortedGoals}
            </Box>
        );
    }
}

export { GoalsSelector };
