import { isSmallSizeFix } from '../../styles';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    viewPager: {
        flex: 1
    },
    webContainer: {
        width: '100%',
        height: '100%'
    },
    webDotsWrapperContainer: { width: '100%', height: isSmallSizeFix ? 124 : 154, position: 'absolute' }
});

export { styles };
