/* eslint-disable */
import _ from 'lodash';
import moment from 'moment';
import { computed, makeObservable, observable, reaction, toJS, when } from 'mobx';
import { Document } from 'firestorter';

import { debugConfigs } from '@emjoy/shared/configs';
import { trackEvent } from 'libs/analytics';

const userStore = observable({
    user: {},
    tempDisplayName: '',
    initialSelectedGoals: {},
    payments: {},
    get userDocument() {
        return this.user?.uid
            ? new Document(`users/${this.user.uid}`, { mode: 'on', debug: debugConfigs.firestorter })
            : {};
    },

    setUserSelectedGoals(location) {
        if (this.user.uid && !_.isEmpty(this.initialSelectedGoals)) {
            const cleanedData = _.pickBy(
                {
                    selectedGoals: _.merge(this.emptyGoals, this.initialSelectedGoals),
                    confidenceLevel: this.confidenceLevel,
                    sexualPartner: this.sexualPartner,
                    completedSessions: this.completedSessions
                },
                _.identity
            );

            this.userDocument.set(cleanedData, { merge: true });

            trackEvent('selectedGoals', { goals: toJS(this.initialSelectedGoals), location: location });
        }
    },
    setUserData(data) {
        if (data.uid) {
            /* eslint-disable no-unused-vars */
            const {
                email,
                emailVerified,
                isAnonymous,
                metadata,
                phoneNumber,
                photoURL,
                providerData,
                providerId,
                uid
            } = data;
            /* eslint-enable no-unused-vars */

            let { displayName } = data;

            if (this.tempDisplayName) {
                displayName = this.tempDisplayName;
                this.user.displayName = displayName;
            }

            this.user = { ...data };

            if (metadata) {
                const newMetadata = {};
                newMetadata.creationTime = moment(metadata.creationTime).valueOf(); //firebase is complainging metadata is readonly :O
                newMetadata.lastSignInTime = moment(metadata.lastSignInTime).valueOf();
                this.user.metadata = newMetadata;
            }

            if (providerData) {
                const newProviderData = JSON.parse(JSON.stringify(providerData)); // ugly workaround, could not make it work without
                this.user.providerData = newProviderData;
            }

            if (this.userDocument.set) {
                this.userDocument.set(this.user, { merge: true });
            } else {
                when(
                    () => this.userDocument.set,
                    () => {
                        this.userDocument.set(this.user, { merge: true });
                    }
                );
            }

            this.tempDisplayName = null;
        }
    },
    resetUserData() {
        //TODO  port from mobile
    }
});

export { userStore };
