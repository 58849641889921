import React from 'react';

// import SafeAreaView from 'react-native-safe-area-view';
import { Box, useCurrentBreakpoint } from '@mobily/stacks';
import { useNavigation, useRoute } from '@react-navigation/native';

import { L } from 'libs/i18n';

import { colors } from '@emjoy/shared/styles';
import { EmButton, EmText } from '@emjoy/shared/primitives';

import { IconButton } from 'components/utils/buttons/IconButton/IconButton';

import styles from 'components/utils/Alert/Alert.styles';
import { images, sizes, textStyle } from 'styles';

const scale = sizes.screenWidth / 375;

const Alert = () => {
    const navigation = useNavigation();
    const route = useRoute();
    const { type, title, message, okButton, onOk, onCancel, cancelButton, testID } = route.params;

    const currentBreakpoint = useCurrentBreakpoint();
    const mobile = currentBreakpoint === 'mobile';

    const onOkButton = () => {
        navigation.goBack();
        onOk?.();
    };

    const onCancelButton = () => {
        navigation.goBack();
        onCancel?.();
    };

    return (
        // <View testID={testID} style={styles.overlay} pointerEvents="auto">
        <Box paddingTop={scale >= 1 ? 19 : 6} style={[styles.overlay, styles.container]}>
            <Box marginX={scale >= 1 ? 6 : 2} style={[styles.wrapper, !mobile ? styles.wrapperDesktop : null]}>
                <Box alignX={'center'} marginTop={3} marginBottom={1}>
                    <IconButton
                        // TODO change to simple Icon , why is this a button
                        size={32}
                        color={colors.primary.default}
                        icon={
                            type === 'info'
                                ? images.informationIcon
                                : type === 'action'
                                ? images.alertIcon
                                : type === 'error'
                                ? images.errorIcon
                                : null
                        }
                        testID={type + 'Icon'}
                    />
                </Box>
                <Box marginX={2} alignX={'center'}>
                    <EmText type={'h4'} testID={'alertTitle'}>
                        {title}
                    </EmText>
                    {message && (
                        <EmText
                            type={'small'}
                            weight={'regular'}
                            color={'neutral.c800'}
                            testID={'alertMessage'}
                            textAlign={'center'}
                            boxProps={{ marginTop: 3 }}
                        >
                            {message}
                        </EmText>
                    )}
                </Box>
                <Box marginTop={2} marginBottom={3} paddingX={6}>
                    <EmButton title={okButton} onPress={onOkButton} testID="emptyFieldsOkBtn" />
                    {cancelButton && (
                        <EmButton
                            type={'textButton'}
                            accessibilityLabel={L('cancel')}
                            accessibilityHint={L('cancel')}
                            title={L('cancel')}
                            onPress={onCancelButton}
                            testID="emptyFieldsCancelBtn"
                        />
                    )}
                </Box>
            </Box>
        </Box>

        // </View>
    );
};

export { Alert };
