// this is a first implementation,
// we just only use lightTheme, in components created by react-navigation,
// background applies to all app screens backgrounds

import { colors } from '../styles/colors';

const lightTheme = {
    dark: false,
    colors: {
        primary: colors.primary.default,
        background: colors.white,
        card: colors.white,
        text: colors.secondary.default,
        border: colors.neutral.c400
    }
};

const darkTheme = {
    dark: true,
    colors: {
        primary: colors.primary.default,
        background: colors.secondary.default,
        card: colors.white,
        text: colors.white,
        border: colors.white
    }
};

export { lightTheme, darkTheme };
