import { isSmallSizeFix, sizes } from '@emjoy/shared/styles';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    backgroundBottomImage: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: isSmallSizeFix ? 180 : 250, //since this is for webview background, numbers are aproximations that looks good in all tested devices
        height: isSmallSizeFix ? 400 : 550
    },
    backgroundTopImage: {
        position: 'fixed',
        top: 0,
        right: 0,
        width: isSmallSizeFix ? 160 : 200, //since this is for webview background, numbers are aproximations that looks good in all tested devices
        height: isSmallSizeFix ? 200 : 280
    },
    sessionCardWebQuizCard: {
        borderRadius: sizes.dim05x,
        overflow: 'hidden',
        width: 272,
        height: 96,
        boxShadow: '0 5px 20px 0 rgba(83,92,170,0.15)' //TODO shadows.js for web?
    },
    questionContainer: { width: '100%', maxWidth: 600 } //this maxWidth 600 is just for when opening in browser doesnt go crazy width, dont affect to app
});

export { styles };
