/* eslint-disable */
// import ReactGA from 'react-ga';

// import { useRouter } from 'next/router';

// import { GA_TRACKING_ID } from '../configs/conf';

//TODO setup tracking for webapp
export const useAnalytics = () => {
    // const router = useRouter();
    return {
        init: () => {
            //         ReactGA.initialize(GA_TRACKING_ID, { debug: false }); // TODO add debug conf
        },
        trackPageViewed: path => {
            // if (path) {
            // return ReactGA.pageview(path);
            // }
            // return ReactGA.pageview(router.asPath);
        },
        trackEvent: params => {
            // ReactGA.event(params);
        }
    };
};
