/* eslint-disable */
import DeviceInfo from 'react-native-device-info';
import { Sanitizer } from '@esri/arcgis-html-sanitizer';

import _ from 'lodash';

const sanitizeHtmlBody = rawHTMLBody => {
    const toSanitize = rawHTMLBody || '';

    const transformedQuotes = toSanitize?.replace('&quot;', "'");

    const customSanitizer = new Sanitizer(
        {
            whiteList: {
                h1: [],
                h2: [],
                h3: [],
                h4: [],
                h5: [],
                p: [],
                b: [],
                i: [],
                em: [],
                u: [],
                s: [],
                strong: [],
                br: [],
                a: [],
                span: []
            }
        },
        true
    );

    const sanitizedHtml = customSanitizer.sanitize(transformedQuotes);

    const cleanedUnnecessaryP = sanitizedHtml
        .replace(/<p><h>/g, '<h>')
        .replace(/<\/h><\/p>/g, '</h>')
        .replace(/<p><br \/><\/p>/g, '')
        .replace(/<p><br><\/p>/g, '')
        .replace(/&nbsp;/g, ' ');

    return cleanedUnnecessaryP;
};
const appInfo = {
    appVersion: DeviceInfo.getVersion(),
    appBuild: DeviceInfo.getBuildNumber(),
    deviceModel: `${DeviceInfo.getBrand()} - ${DeviceInfo.getModel()}`,
    operatingSystem: DeviceInfo.getSystemName(),
    operatingSystemVersion: DeviceInfo.getSystemVersion(),
    bundleId: DeviceInfo.getBundleId()
};

const stripCategory = category => {
    let strippedCategory = {};
    if (category) {
        strippedCategory = _.cloneDeep(category); //Avoid infinite loops
        delete strippedCategory.sessions;
        delete strippedCategory.learningSessions;
    }

    return strippedCategory;
};

export { sanitizeHtmlBody, appInfo, stripCategory };
