import React from 'react';

import { Box, useCurrentBreakpoint } from '@mobily/stacks';
import { SafeAreaView } from 'react-native-safe-area-context';

import { Caption, FooterImage } from 'components/onboarding';
import { Header } from 'layout/Header/Header';

import { colors } from '@emjoy/shared/styles';
import { wrapperStyle } from './Wrapper.styles';

const DesktopWrapper = ({ children, noApp, noHeader, currentPosition, isNewUser }) => {
    //TODO move in layout maybe

    return (
        <Box flex={'fluid'} alignSelf={'stretch'} style={wrapperStyle.desktopMain}>
            <SafeAreaView style={[wrapperStyle.safearea]}>
                <Box
                    alignSelf={'center'}
                    style={wrapperStyle.desktopWrapper}
                    flex={'fluid'}
                    paddingX={3}
                    direction={'row'}
                >
                    <Box flex={'1/2'}>
                        <Box>
                            {!noHeader && <Header noSocial logoColor={colors.primary.default} />}

                            <Caption currentPosition={currentPosition} isNewUser={isNewUser} />
                        </Box>
                    </Box>
                    <Box flex={'1/2'} alignX={'right'} alignY={'center'}>
                        <Box style={[noApp ? wrapperStyle.noApp : wrapperStyle.withApp]}>{children}</Box>
                    </Box>
                </Box>
            </SafeAreaView>
        </Box>
    );
};

const MobileWrapper = ({ children }) => {
    //TODO move in layout maybe
    return (
        <Box flex={'fluid'} alignX={'center'} alignY={'center'}>
            {children}
        </Box>
    );
};
const Wrapper = ({ children, noApp, noHeader, currentPosition, isNewUser }) => {
    const currentBreakpoint = useCurrentBreakpoint();
    const mobile = currentBreakpoint === 'mobile';

    return (
        <Box flex={'fluid'}>
            <FooterImage />
            {mobile ? <MobileWrapper>{children}</MobileWrapper> : null}
            {!mobile ? (
                <DesktopWrapper
                    noHeader={noHeader}
                    noApp={noApp}
                    currentPosition={currentPosition}
                    isNewUser={isNewUser}
                >
                    {children}
                </DesktopWrapper>
            ) : null}
        </Box>
    );
};

export { Wrapper, MobileWrapper, DesktopWrapper };
