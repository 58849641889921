import React from 'react';
import { Image, useWindowDimensions } from 'react-native';

import { useCurrentBreakpoint } from '@mobily/stacks';

import { FooterImageStyle } from './FooterImage.styles';
import { images } from 'styles';

const FooterImage = () => {
    const { width } = useWindowDimensions();
    const maxWidth = width * 0.7;
    const currentBreakpoint = useCurrentBreakpoint();

    const mobile = currentBreakpoint === 'mobile';

    let finalWidth;
    if (mobile) {
        finalWidth = width;
    } else {
        finalWidth = mobile ? width : width > maxWidth ? maxWidth : width - 50;
    }

    const height = mobile ? finalWidth / 1.953 : finalWidth / 5; //image aspect ratio

    return (
        <Image
            source={mobile ? images.webapp.webOnboaringFooterWaveMobile : images.webapp.webOnboaringFooterWaveDesktop}
            style={[FooterImageStyle.footerImageStyle, { width: finalWidth, height }]}
            resizeMode={'stretch'}
        />
    );
};

export { FooterImage };
