/* eslint-disable */
import React from 'react';

import _ from 'lodash';
import { Box, Stack } from '@mobily/stacks';
import { TouchableOpacity } from 'react-native';

import { EmButton, EmImage, EmText } from '@emjoy/shared/primitives';
import { L } from 'src/libs/i18n';
import { observer } from 'mobx-react';
import { WebQuizRadioButtonGroup } from 'src/pages/WebQuiz/WebQuizRadioButtonGroup/WebQuizRadioButtonGroup';
import { webQuizStore } from './WebQuiz';
import { webViewStore } from 'src/screens/WebView/WebView';

import { styles } from './webQuiz.styles';

const WelcomeComponent = ({ welcomeQuestion }) => {
    const { setNextPage, testId } = webQuizStore;
    webViewStore.trackEventInApp({ name: 'startQuiz', params: { quizId: testId } });
    return (
        <Box flex={'fluid'} alignY={'between'} paddingY={6} paddingX={2}>
            <Box /> {/* Doing this empty box, 'between' alignment centers the text and push the button to the bottom*/}
            <Box>
                <Stack space={2} padding={2} type={'h2'} align={'center'} weight={'regular'}>
                    <EmText color={'white'}>{welcomeQuestion.title}</EmText>
                    <EmText textAlign={'center'} type={'h1'} color={'white'}>
                        {welcomeQuestion.text}
                    </EmText>
                </Stack>
            </Box>
            <EmButton type={'outlinedWhite'} onPress={setNextPage} title={welcomeQuestion.buttonCta} />
        </Box>
    );
};

const QuestionComponent = observer(({ question }) => {
    const { setNextPage, surveyTest } = webQuizStore;
    const textColor = question?.colorScheme === 'dark' && 'white';

    return (
        <Box flex={'fluid'} alignY={'between'} paddingY={6} paddingX={2} style={styles.questionContainer}>
            <Box /> {/* Doing this empty box, 'between' alignment centers the text and push the button to the bottom*/}
            <Stack space={2}>
                <EmText textAlign={'center'} type={'h2'} color={textColor}>
                    {question.text}
                </EmText>
                <EmText textAlign={'center'} type={'small'} color={textColor} weight={'regular'}>
                    {surveyTest.chooseQuestionText}
                </EmText>
                {question.options && (
                    <WebQuizRadioButtonGroup question={question} percentages={webQuizStore.answerPercentages} />
                )}
            </Stack>
            <EmButton onPress={setNextPage} title={L('next')} disabled={!webQuizStore.questionAnswered(question.id)} />
        </Box>
    );
});

const ConclusionComponent = () => {
    const { conclusion, setNextPage, answers } = webQuizStore;

    return (
        <Box flex={'fluid'} alignY={'between'} paddingY={6} paddingX={2}>
            <Box /> {/* Doing this empty box, 'between' alignment centers the text and push the button to the bottom*/}
            <Stack space={2} align={'center'}>
                <EmImage width={100} height={127} source={conclusion.badgeImg} />
                <EmText type={'h2'}>{conclusion.title}</EmText>
                <EmText type={'h3'}>{conclusion.subTitle}</EmText>
                <EmText textAlign={'center'} weight={'regular'}>
                    {conclusion.text}
                </EmText>
            </Stack>
            {_.size(answers) ? <EmButton onPress={setNextPage} title={L('see_suggestions')} /> : <Box />}
        </Box>
    );
};

const ResultComponent = () => {
    const { conclusion, surveyTest } = webQuizStore;
    const textColor = conclusion?.colorScheme === 'dark' && 'white';

    return (
        <Stack space={4} align={'center'}>
            <EmText color={textColor} type={'h2'}>
                {surveyTest.conclusionsText.title}
            </EmText>
            <Stack space={2} align={'center'}>
                {_.map(conclusion.sessions, (session, index) => (
                    <SessionCardWebQuiz key={index} session={session} />
                ))}
            </Stack>
        </Stack>
    );
};

//TODO use shared SessionCard almost imposible due to reusable components not working well (EMCard etc)
const SessionCardWebQuiz = ({ session }) => {
    const sessionIcon =
        session.isFree ||
        webViewStore.userStore?.hasValidSubscription ||
        (webViewStore.userStore?.hasReferralCollection && session.sessionCategory === '7 Days of Pleasure') // Ugly, we need to improve how webapp access data
            ? 'playGrey'
            : 'lockGrey';

    const onSessionPress = () => {
        webViewStore.appNavigate('SessionDetails', {
            sessionId: session.sessionId,
            shouldGoHome: true,
            location: webQuizStore.testId
        });
    };
    return (
        <TouchableOpacity
            accessible={true}
            accessibilityLabel={`${L('session')} ${session.sessionTitle}.`}
            accessibilityRole={'button'}
            onPress={onSessionPress}
        >
            <Box style={styles.sessionCardWebQuizCard} direction={'row'}>
                <EmImage width={88} height={96} source={session.thumbnailHome} resizeMode={'cover'} />
                <Box padding={1} flex={'fluid'} alignY={'around'}>
                    <EmText type={'h6'}>{session.sessionTitle}</EmText>
                    <EmText type={'small'} color={'neutral.c800'}>
                        {session.sessionCategory}
                    </EmText>
                    <Box alignSelf={'right'}>
                        <EmImage width={24} height={24} source={sessionIcon} />
                    </Box>
                </Box>
            </Box>
        </TouchableOpacity>
    );
};

export { WelcomeComponent, SessionCardWebQuiz, ResultComponent, ConclusionComponent, QuestionComponent };
