import _ from 'lodash';
import { observer } from 'mobx-react';

import { appStore, userStore } from '@stores';

// Usage:
// 1 - Boolean
// withFeatureFlag({ id: 'feature_flag_id' })
//
// 2 - Return component if true
// withFeatureFlag({
//     id: 'feature_flag_id',
//     component: <Component />
// })
//
// 3 - conditional export on module
// const Example = () => (
//    <FeatureFlag id={'voice_poll'}>
//         <ExampleComponent />
//    </FeatureFlag>
//  );
// export { Example };

function withFeatureFlag({ component, id }) {
    const featureFlag = _.find(appStore.featureFlags, { id });

    if (featureFlag?.teamMembers && _.includes(userStore.userDocument.data?.email, 'letsemjoy.com')) {
        return true;
    }

    if (component) {
        return featureFlag?.active ? component : null;
    } else {
        return featureFlag?.active ? true : false;
    }
}

function featureFlagInfo({ id }) {
    return _.find(appStore.featureFlags, { id });
}

const FeatureFlag = observer(({ children, id }) => {
    const featureFlag = _.find(appStore.featureFlags, { id });
    return featureFlag?.active ? children : null;
});

export { withFeatureFlag, FeatureFlag, featureFlagInfo };
