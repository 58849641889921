import React from 'react';

import { Box } from '@mobily/stacks';
import { useAnalytics } from 'libs/gtag';

const EmLink = ({ children, onClick, target, rel, href, dataGaTrack, style, ...rest }) => {
    const { trackEvent } = useAnalytics();

    return (
        <Box style={style}>
            <a
                style={{ textDecoration: 'none' }}
                target={target || null}
                rel={rel || null}
                href={href}
                onClick={() => {
                    dataGaTrack && dataGaTrack.category && dataGaTrack.action && trackEvent(dataGaTrack);
                    onClick?.();
                }}
                {...rest}
            >
                {children}
            </a>
        </Box>
    );
};

export { EmLink };
