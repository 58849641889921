/* eslint-disable */
import React, { Component } from 'react';
import { Box } from '@mobily/stacks';
import { Text } from 'react-native';

import { colors, images } from 'styles';
import { IconButton } from 'components/utils';
import { L } from 'libs/i18n';

import styles from './Toolbar.styles';

class Toolbar extends Component {
    render() {
        return (
            <Box
                direction={'row'}
                alignX={'between'}
                alignY={'center'}
                style={{ ...styles.wrapper, ...this.props.style }}
                {...this.props.boxProps}
            >
                {(this.props.hasClose || this.props.hasBack) && (
                    <IconButton
                        accessible={true}
                        accessibilityLabel={this.props.hasClose ? L('close') : L('back')}
                        accessibilityRole="button"
                        size={24}
                        icon={this.props.hasClose ? 'closeNoCircleButton' : 'flatBackButton'}
                        color={this.props.closeColor || colors.darkGreyBlue}
                        onPress={this.props.onClose}
                        testID={'closeBackBtn'}
                    />
                )}
                {!this.props.hasBack && !this.props.hasClose && <IconButton size={24} icon={null} disabled />}
                {this.props.title && (
                    <Text style={{ ...styles.title, ...this.props.titleStyle }}>{this.props.title}</Text>
                )}
                {this.props.rightButton && (
                    <IconButton
                        accessible={true}
                        accessibilityLabel={L('settings')}
                        size={24}
                        icon={this.props.rightButton}
                        color={this.props.rightColor || colors.darkGreyBlue}
                        onPress={this.props.onRight}
                        testID={'settingsBtn'}
                    />
                )}
                {!this.props.rightButton && <IconButton size={24} icon={null} disabled />}
            </Box>
        );
    }
}

export { Toolbar };
