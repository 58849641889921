import _ from 'lodash';
import axios from 'axios';
import Config from 'react-native-config';

// import { withFeatureFlag } from 'src/libs/featureFlags';

// import Purchases from 'react-native-purchases';

// import { abTestsMainSubscriptions, allRewardsCampaigns, debug, debugConfigs, subscriptionsInfo } from '@configs';
// import { subscriptionsInfo } from '@configs';
// import { appStore, userStore } from '@stores';
// import { getReceiverReferalCode, getReceiverReferalCodeNotRedeemed } from '@libs/referrals';

// import { getIdToken } from '@libs/signin';

import { revenueCatApiKey } from 'configs/conf';

// import { createStripeSubscription, getRevenueCatUser, getStripePrices, initStripe } from './stripe';
import { L } from './i18n';

const checkLegacySubscriptionUrl = Config.CHECK_LEGACY_SUBSCRIPTION_URL;
const isRelease = Config.APP_TARGET === 'release';

// const SUBSCRIPTIONS = JSON.parse(Config.SUBSCRIPTIONS);

// Purchases.addCustomerInfoUpdateListener(async info => {
//     debugConfigs.payments && debug.log('Purchases addCustomerInfoUpdateListener: ', info);
//     userStore.payments.purchaserInfo = info; // handle any changes to purchaserInfo
//     await getSubscriptions();
// });

// const migrateSubscriptionToRevenueCat = async () => {
//     // console.warn('we migrate to RevenueCat', userStore.userDocument.id);
//     try {
//         const restore = await Purchases.restoreTransactions();
//         // console.log('Purchase restored', { restore });
//         return !!restore;
//     } catch (error) {
//         // console.log('Purchase restored error', { error });
//         return false;
//     }
// };

// const checkLegacySubscription = async userId => {
//     try {
//         const idToken = await getIdToken();
//         const res = await axios({
//             method: 'POST',
//             url: checkLegacySubscriptionUrl,
//             data: { userId },
//             headers: {
//                 Authorization: `Bearer ${idToken}`
//             },
//             timeout: 30000
//         });
//         // console.log('Purchase checkLegacySubscription', { res });

//         return res.data?.hasLegacySubscription;
//     } catch (error) {
//         return false;
//     }
// };

// const getSubscriptions = async () => {
//     try {
//         userStore.payments.purchaserInfo = await Purchases.getCustomerInfo();

//         debugConfigs.payments &&
//             debug.log('Purchases addCustomerInfoUpdateListener: ', userStore.payments.purchaserInfo);

//         const offerings = (await Purchases.getOfferings()) || {};
//         const { current } = offerings || {}; //(await Purchases.getOfferings()) || {};
//         const { availablePackages } = current || [];

//         debugConfigs.payments && debug.log('Purchases: ', { offerings, availablePackages });

//         if (availablePackages) {
//             userStore.payments.availableSubscriptions = _.map(subscriptionsInfo, item => {
//                 item.subscriptionRC = _.find(availablePackages, { identifier: item.packageId });
//                 return item;
//             });

//             userStore.payments.mainSubscriptions = _.filter(userStore.payments.availableSubscriptions, item => {
//                 return _.includes(abTestsMainSubscriptions.default, item.packageId);
//             });

//             userStore.payments.referralsSubscriptions = _.filter(
//                 userStore.payments.availableSubscriptions,
//                 'referralType'
//             );

//             userStore.payments.offersSubscriptions = _.filter(userStore.payments.availableSubscriptions, 'isOffer');
//         }

//         debugConfigs.payments && debug.log('Purchases getSubscriptions success: ', userStore.payments);

//         appStore.paymentsInit = true;
//     } catch (error) {
//         debugConfigs.payments && debug.log('Purchases getSubscriptions error: ', error);
//         appStore.paymentsInit = true;
//     }
// };

// TODO: A/B tests, deals, and other price changes can go here
// const getMainOffering = () => {
//     // console.log({ RC: userStore.subscriptionDataRC });
//     return userStore.subscriptionDataRC?.[Config.SUBSCRIPTION_ID]?.[Config.MAIN_SUBSCRIPTION_OFFERING];
// };

// const getReferralSubscription = () => {
//     let referralData = null;
//     let referralSubscription = null;
//     _.forEach(allRewardsCampaigns.referralCampaignsAllowed, referralTypeData => {
//         const referralInfo =
//             referralTypeData.referralType === 'referral_collaborators_code'
//                 ? getReceiverReferalCodeNotRedeemed({ referralType: referralTypeData.referralType })
//                 : getReceiverReferalCode({ referralType: referralTypeData.referralType });

//         if (referralInfo) {
//             referralData = { ...referralInfo, ...referralTypeData };
//         }
//     });

//     debugConfigs.payments &&
//         debug.log(116, 'Purchases getReferralSubscription: ', {
//             referralData,
//             userStore
//         });

//     if (referralData) {
//         referralSubscription = _.find(userStore.payments.referralsSubscriptions, ({ referralType }) => {
//             return referralType === referralData.referralType;
//         });
//         referralSubscription.referralData = referralData;
//     }

//     return referralSubscription;
// };

// const getPurchaserInfo = async () => {
//     userStore.purchaserInfo = await Purchases.getCustomerInfo();

//     // console.log('purchases getPurchaserInfo', { purchaserInfo: userStore.purchaserInfo });
//     // console.log('Purchase purchaserInfo', userStore.purchaserInfo);
//     // console.log('Purchase getAppUserID', await Purchases.getAppUserID());
// };

// const buySubscription = async purchasePackage => {
//     try {
//         const purchaseResult = await Purchases.purchasePackage(purchasePackage);

//         userStore.payments.purchaserInfo = purchaseResult.purchaserInfo;

//         const activeEntitlements = userStore.payments.purchaserInfo?.entitlements?.active;
//         // TODO for some reason userStore.hasValidSubscription is not triggering in time here

//         if (!!_.size(activeEntitlements)) {
//             debugConfigs.payments &&
//                 debug.log('Purchases buySubscription success: ', { purchasePackage, activeEntitlements });
//             return { success: true };
//         } else {
//             debugConfigs.payments &&
//                 debug.log('Purchases buySubscription error no entititlements : ', { purchasePackage });
//             return { error: 'current subscription not active' };
//         }
//     } catch (error) {
//         debugConfigs.payments && debug.log('Purchases buySubscription error: ', { error });
//         // console.log('purchase.error 150', JSON.stringify(error), error.code, error.message);
//         return { error };
//     }
// };

// const restorePurchase = async () => {
//     try {
//         const restore = await Purchases.restoreTransactions();
//         // console.log({ restore });

//         userStore.purchaserInfo = restore;
//         if (!userStore.hasValidSubscription) {
//             return { error: "Either you don't have a subscription, or it's expired" };
//         }
//     } catch (error) {
//         return { error };
//     }
// };

// const initPayment = async () => {
//     await Purchases.setDebugLogsEnabled(!!__DEV__);

// ===================
// Setup RevenueCat for the new 5 version using separate api keys per platfrom as in mobileapp
// ===================

//     await Purchases.setup(Config.REVENUECAT, userStore.userDocument.id);
//     // await Purchases.setup(Config.REVENUECAT, userStore.userDocument.id, false, '');
//     // TODO I got an error at some time that needed this to work, but might have been only stall metro. Keep an eye on it
//     await Purchases.setAllowSharingStoreAccount(false);

//     userStore.flowControl.paymentSetup = true;
//     debugConfigs.payments && debug.log('Purchases initPayment');
// };

// const setupUser = async () => {
//     if (userStore.userDocument.id) {
//         await Purchases.createAlias(userStore.userDocument.id);
//TODO deprecated, use login as per
// https://github.com/RevenueCat/react-native-purchases/blob/14e090ab13e51701de13d5725275a35183c0059c/CHANGELOG.md
//         debugConfigs.payments && debug.log('Purchases setupUser');
//         // try { // we don't do this for now as it seems it created issues on ios, keep an eye on it
//         // await Purchases.restoreTransactions();
//         // } catch (error) {}
//     }
// };

const axiosInstance = axios.create({
    baseURL: 'https://api.revenuecat.com/v1',
    headers: { 'X-Platform': 'stripe', Authorization: `Bearer ${revenueCatApiKey}` }
});

const rawMainSubscriptions = [
    {
        periodText: 'Annual plan',
        freeTrialText: 'Start 7-Day Free Trial',
        text: 'Payment will be made immediately',
        text_trial: 'Payment will be made after the 7 days trial ends',
        subscriptionId: 'com.letsemjoy.yearly2999',
        packageId: 'yearly2999',
        // discountedSubscription: 'com.letsemjoy.welcome4999',
        offering: 'yearly',
        period: 'yearly',
        hasTrial: true,
        isOffer: false,
        isHighlighted: true,
        freeTrialDaysText: 'First 7 days free',
        subscriptionRC: {
            offeringIdentifier: 'default',
            product: {
                discounts: [],
                intro_price_string: '0,00 $',
                intro_price_period_number_of_units: 1,
                identifier: 'com.letsemjoy.yearly2999',
                intro_price_cycles: 1,
                priceString: '29,99 $',
                intro_price_period: 'P1W',
                title: 'Emjoy Subscription',
                price: 29.99,
                currency_code: 'usd',
                intro_price_period_unit: 'WEEK',
                intro_price: 0,
                introPrice: {
                    periodUnit: 'WEEK',
                    price: 0,
                    period: 'P1W',
                    priceString: '0,00 $',
                    periodNumberOfUnits: 1,
                    cycles: 1
                },
                description: 'Yearly subscription'
            },
            packageType: 'CUSTOM',
            identifier: 'yearly2999'
        },
        // stripePriceId: 'price_1IahboJxx7mFaucIJOeJvLe8', //TEST
        stripePriceId: 'price_1JZBN2Jxx7mFaucIoyvyGQLU', //PROD
        // stripePriceId: isRelease ? 'price_1JZBN2Jxx7mFaucIoyvyGQLU' : 'price_1IahboJxx7mFaucIJOeJvLe8', // TODO check why this doesn't work on release
        trialDays: 7
    },
    {
        periodText: 'Annual plan',
        freeTrialText: 'Start 7-Day Free Trial',
        text: 'Payment will be made immediately',
        text_trial: 'Payment will be made after the 7 days trial ends',
        subscriptionId: 'com.letsemjoy.yearly3499',
        packageId: 'yearly3499',
        // discountedSubscription: 'com.letsemjoy.welcome4999',
        offering: 'yearly',
        period: 'yearly',
        hasTrial: true,
        isOffer: false,
        isHighlighted: true,
        freeTrialDaysText: 'First 7 days free',
        subscriptionRC: {
            offeringIdentifier: 'default',
            product: {
                discounts: [],
                intro_price_string: '0,00 $',
                intro_price_period_number_of_units: 1,
                identifier: 'com.letsemjoy.yearly3499',
                intro_price_cycles: 1,
                priceString: '34,99 $',
                intro_price_period: 'P1W',
                title: 'Emjoy Subscription',
                price: 34.99,
                currency_code: 'usd',
                intro_price_period_unit: 'WEEK',
                intro_price: 0,
                introPrice: {
                    periodUnit: 'WEEK',
                    price: 0,
                    period: 'P1W',
                    priceString: '0,00 $',
                    periodNumberOfUnits: 1,
                    cycles: 1
                },
                description: 'Yearly subscription'
            },
            packageType: 'CUSTOM',
            identifier: 'yearly3499'
        },
        // stripePriceId: 'price_1JVKJIJxx7mFaucINhdfJzTn', //TEST
        stripePriceId: 'price_1JZBBVJxx7mFaucIFaLPs2hs', //PROD
        // stripePriceId: isRelease ? 'price_1JZBBVJxx7mFaucIFaLPs2hs' : 'price_1JVKJIJxx7mFaucINhdfJzTn', //PROD
        trialDays: 7
    },
    {
        subscriptionId: 'com.letsemjoy.quarterly1699',
        packageId: 'quarterly1699',
        offering: 'quarterly',
        period: 'quarterly',
        periodText: 'Quarterly plan',
        text: 'Payment will be made immediately',
        isOffer: false,
        hasTrial: false,
        subscriptionRC: {
            offeringIdentifier: 'default',
            product: {
                discounts: [],
                intro_price_string: null,
                intro_price_period_number_of_units: null,
                identifier: 'com.letsemjoy.quarterly1699',
                intro_price_cycles: null,
                priceString: '16,99 $',
                intro_price_period: null,
                title: 'Emjoy Subscription',
                price: 16.99,
                currency_code: 'usd',
                intro_price_period_unit: null,
                intro_price: null,
                introPrice: null,
                description: 'Quarterly Subscription'
            },
            packageType: 'CUSTOM',
            identifier: 'quarterly1699'
        },
        // stripePriceId: 'price_1IjKW2Jxx7mFaucIarUmSWKR' //TEST
        stripePriceId: 'price_1JZBM0Jxx7mFaucIvSLU0HNe' //PROD
        // stripePriceId: isRelease ? 'price_1JZBM0Jxx7mFaucIvSLU0HNe' : 'price_1IjKW2Jxx7mFaucIarUmSWKR' //PROD
    }
];

const abTestsMainSubscriptions = {
    // default: ['yearly2999', 'quarterly1699'],
    // variant: ['yearly3499', 'quarterly1699']
    // we disable all types of subscriptions except monthly1099
    default: ['monthly1099']
};

const getSubscriptions = async country => {
    // const result = await axiosInstance.get('subscribers/app_user_id/offerings');

    // const {
    //     data: { current_offering_id, offerings }
    // } = result;

    // const getCountryLocationLowerCase = country.toLowerCase();

    // const mainSubscriptions = _.filter(rawMainSubscriptions, (item) => {
    //     switch (getCountryLocationLowerCase) {
    //         case 'us':
    //             if (withFeatureFlag({ id: 'priceIncrease3499Us' })) {
    //                 return _.includes(abTestsMainSubscriptions.variant, item.packageId);
    //             } else {
    //                 return _.includes(abTestsMainSubscriptions.default, item.packageId);
    //             }

    //         case 'gb':
    //             if (withFeatureFlag({ id: 'priceIncrease3499Uk' })) {
    //                 return _.includes(abTestsMainSubscriptions.variant, item.packageId);
    //             } else {
    //                 return _.includes(abTestsMainSubscriptions.default, item.packageId);
    //             }

    //         default:
    //             if (withFeatureFlag({ id: 'priceIncrease3499Row' })) {
    //                 return _.includes(abTestsMainSubscriptions.variant, item.packageId);
    //             } else {
    //                 return _.includes(abTestsMainSubscriptions.default, item.packageId);
    //             }
    //     }
    // });

    return rawMainSubscriptions;
};

export {
    // checkLegacySubscription,
    // migrateSubscriptionToRevenueCat,
    // getMainOffering,
    // getPurchaserInfo,
    // buySubscription,
    getSubscriptions
    // restorePurchase,
    // initPayment,
    // setupUser,
    // getReferralSubscription,
    // createStripeSubscription,
    // initStripe,
    // getStripePrices,
    // getRevenueCatUser
};
