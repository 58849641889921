import { StyleSheet } from 'react-native';

import { colors } from '@emjoy/shared/styles';
import { sizes, textStyle } from 'styles';

const scale = sizes.screenWidth / 375;

const style = StyleSheet.create({
    pageTitle: {
        ...textStyle.titleBold,
        textAlign: 'left',
        color: colors.secondary.c900,
        marginHorizontal: sizes.dim3x,
        marginTop: sizes.dim3x,
        letterSpacing: 0.3,
        paddingBottom: sizes.dim05x,
        alignItems: 'center'
    },

    button: {
        height: sizes.dim6x,
        borderRadius: sizes.dim05x,
        borderColor: colors.secondary.default,
        borderWidth: 1
    },
    textButton: {
        marginTop: sizes.dim2x
    },

    mainContainer: {
        width: '100%'
    },

    progressWrapper: {
        width: 0.7 * sizes.screenWidth,
        height: sizes.dim05x,
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: colors.secondary.default
    },
    progressBar: {
        height: sizes.dim05x,
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: colors.primary.default
    },
    progressImagesWrapper: {
        left: -40,
        width: sizes.screenWidth,
        height: 120
    },

    progressImage: {
        marginRight: sizes.dim1x,
        borderRadius: 4
    },
    goalsWrapper: {
        justifyContent: 'space-around',
        flexDirection: 'column',
        padding: sizes.dim2x,
        paddingTop: 0
    },

    keyboardAvoidView: {
        flex: 1
    },
    keyboardAvoidViewContent: {
        paddingTop:
            sizes > 1
                ? (sizes.dim5x * 2 + sizes.statusBarHeight) * scale
                : (sizes.dim3x * 2 + sizes.statusBarHeight) * scale,
        justifyContent: 'center'
    },
    keyboardAvoidViewContentLogIn: {
        paddingTop: sizes > 1 ? (sizes.dim5x * 2 + sizes.statusBarHeight) * scale : sizes.statusBarHeight * scale,
        justifyContent: 'center'
    },
    flexOne: {
        flex: 1
    },
    viewPager: {
        flex: 1,
        marginTop: (sizes.dim5x * 2 + sizes.statusBarHeight) * scale
    },
    progressImagesContainter: {
        width: '100%'
    },

    svgHeader: {
        position: 'absolute',
        top: 0
    },
    continueBtn: {
        flex: 1
    },
    ssoApple: {
        backgroundColor: colors.black,
        borderRadius: sizes.dim3x
    },
    ssoGoogle: {
        backgroundColor: colors.sso.google,
        borderRadius: sizes.dim3x
    },
    ssoFacebook: {
        backgroundColor: colors.sso.facebook,
        borderRadius: sizes.dim3x
    },
    sv: { flex: 1 },
    svMobile: { minHeight: 667 }
});

export default style;
