import React from 'react';

import { Box, Row, Rows, useCurrentBreakpoint } from '@mobily/stacks';
import { useNavigation } from '@react-navigation/native';

import { L } from 'libs/i18n';

import { EmButton, EmImage, EmText } from '@emjoy/shared/primitives';
import { Toolbar } from '@components/Toolbar/Toolbar';

import { colors } from '@emjoy/shared/styles';
import { sizes } from 'styles';

import styles from './SuccessScreen.styles';

import { onboardingStore } from 'stores';

const scale = sizes.screenWidth / 375;

const SuccessScreen = () => {
    const navigation = useNavigation();

    const currentBreakpoint = useCurrentBreakpoint();
    const mobile = currentBreakpoint === 'mobile';

    const onNext = () => {
        onboardingStore.currentPosition = 4;
        navigation.goBack();
    };

    return (
        <Box alignY={mobile ? 'center' : 'top'} paddingTop={mobile ? 6 : 19} style={[styles.overlay, styles.container]}>
            <Box
                marginX={scale >= 1 ? 6 : 2}
                padding={1}
                alignX={'center'}
                style={[styles.wrapper, !mobile ? styles.wrapperDesktop : null]}
            >
                <Rows space={1} alignY="evenly">
                    <Row height="content">
                        <Toolbar hasClose onClose={onNext} closeColor={colors.secondary.c900} />
                    </Row>
                    <Row height="content">
                        <EmImage
                            boxProps={{ alignSelf: 'center' }}
                            resizeMode={'contain'}
                            fadeDuration={0}
                            source={'successScreenBadge'}
                            height={scale > 1 ? 120 : 80}
                            width={scale > 1 ? 144 : 96}
                        />
                    </Row>
                    <Row height="content" padding={2}>
                        <EmText type={'h4'} testID={'alertTitle'} textAlign={'center'}>
                            {L('success_screen_congrats')}
                        </EmText>
                        <EmText
                            type={'small'}
                            weight={'regular'}
                            color={'neutral.c800'}
                            testID={'alertMessage'}
                            textAlign={'center'}
                            boxProps={{ marginTop: 1 }}
                        >
                            {L('success_screen_message')}
                        </EmText>
                    </Row>
                    <Row height="content">
                        <Box alignX={'center'}>
                            <EmButton
                                title={L('continue')}
                                onPress={onNext}
                                testID="emptyFieldsOkBtn"
                                width={!mobile ? 300 : 200}
                            />
                        </Box>
                    </Row>
                </Rows>
            </Box>
        </Box>
    );
};

export { SuccessScreen };
