import { StyleSheet } from 'react-native';

import { colors, sizes } from '@emjoy/shared/styles';
import { EmButtonProps } from './EmButton.types';

const styles = (props: EmButtonProps) => {
    const { title, disabled, iconLeft, iconRight, iconSize } = props;

    const ICON_SIZE_DEFAULT: number = sizes.dim25x;
    const mIconSize = iconSize || ICON_SIZE_DEFAULT;

    return StyleSheet.create({
        button: {
            // higher borderRadius is not a problem, not perfect solution for any paddingVertical but covers all our cases
            borderRadius: sizes.dim9x,
            backgroundColor: colors.primary.default,
            ...(disabled && { opacity: 0.7 }),
            // circle style when button only has icons
            ...((iconLeft || iconRight) && !title && { height: mIconSize * 2, width: mIconSize * 2 }),
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row'
        },
        buttonText: { letterSpacing: 0.5, color: colors.white },
        //white Button
        whiteButton: { backgroundColor: colors.white, borderWidth: 1, borderColor: colors.white },
        whiteText: { color: colors.primary.default },
        //Primary Button
        primaryButton: {
            backgroundColor: colors.primary.default,
            ...(disabled && { opacity: 1, backgroundColor: colors.primary.c100 })
        },
        primaryText: {
            color: colors.white
        },
        //Primary Clear Button
        primaryClearButton: {
            backgroundColor: colors.primary.c50
        },
        primaryClearText: {
            color: colors.primary.default
        },
        //Outlined Button
        outlinedButton: {
            borderWidth: 1,
            borderColor: colors.primary.default,
            backgroundColor: 'transparent'
        },
        outlinedText: {
            color: colors.primary.default
        },
        //OutlinedWhite Button
        outlinedWhiteButton: {
            borderWidth: 1,
            borderColor: colors.white,
            backgroundColor: 'transparent'
        },
        outlinedWhiteText: {},
        //Transparent Button
        textButtonButton: {
            backgroundColor: 'transparent'
        },
        textButtonText: {
            color: colors.primary.default
        },
        //Transparent Button
        textWhiteButtonButton: {
            backgroundColor: 'transparent'
        },
        textWhiteButtonText: {
            color: colors.white
        }
    });
};

export default styles;
