import _ from 'lodash';
import Config from 'react-native-config';

//eslint-disable-next-line no-console
const debug = { log: console.log, error: console.error };

const debugConfigs = {
    //SHARED!!
    abTesting: false,
    trackEvents: false,
    firestorter: false,
    notifications: false,
    payments: false,
    deeplink: false,
    promoCode: false,
    forceValidSubscription: false,
    forceCanDoTrial: false,
    forceCanDoOffer: false,
    forceStoriesFeedback: false,
    forceVoiceOverPoll: false,
    oneSignal: false,
    signInEmailLinkUI: false,
    forceScreen: false, // screen name, ex: 'Offer'
    dontSendDynamicWelcomeEmail: false,
    callLocalFunctions: false
};

const setDev = () => {
    _.each(debugConfigs, (value, key, obj) => {
        obj[key] = __DEV__ && value;
    });
};

// fix this when moving mobileapp to use shared/debugConfigs
// if (__DEV__ && Config.IS_DETOX) {
//     const detoxTestConfigs = require('../../__e2e__/detoxTestConfigs');
//     _.merge(debugConfigs, detoxTestConfigs);
// }

setDev();

export { debug, debugConfigs };
