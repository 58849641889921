/* eslint-disable */
import React from 'react';

import _ from 'lodash';
import { Box, Inline } from '@mobily/stacks';

import { EmLink } from '../EmLink/EmLink';
import { Image } from 'react-native';

import { facebook, instagram, linkedin, twitter, youtube } from 'assets/img';

const socialNetworks = {
    facebook: {
        url: 'https://www.facebook.com/letsemjoy/',
        name: 'Facebook',
        img: facebook
    },
    instagram: {
        url: 'https://www.instagram.com/letsemjoy/',
        name: 'Instagram',
        img: instagram
    },
    twitter: {
        url: 'https://twitter.com/letsemjoy',
        name: 'Twitter',
        img: twitter
    },
    linkedin: {
        url: 'https://www.linkedin.com/company/letsemjoy/',
        name: 'LinkedIn',
        img: linkedin
    },
    youtube: {
        url: 'https://www.youtube.com/c/letsemjoy',
        name: 'YouTube',
        img: youtube
    }
};

const SocialButton = ({ network, style }) => {
    // Use html link for external links instead of Link component
    return (
        <Box
            alignX={'center'}
            alignY={'center'}
            style={[
                style,
                {
                    backgroundColor: 'rgba(44,29,59,0.4)',
                    width: 40,
                    height: 40,
                    borderRadius: 12
                }
            ]}
        >
            <EmLink target="_blank" rel="noindex, nofollow, noreferrer" href={network.url}>
                <Image source={{ uri: network.img }} style={{ width: 16, height: 16 }} />
            </EmLink>
        </Box>
    );
};

const SocialButtons = () => {
    //*For some reason inline won't work without Box here
    return (
        <Inline align={'right'} space={2}>
            {_.map(socialNetworks, network => (
                <SocialButton key={network.name} network={network} />
            ))}
        </Inline>
    );
};

export { SocialButtons };
