import React, { Component } from 'react';
import { Box } from '@mobily/stacks';
import { EmText } from '@emjoy/shared/primitives';

import styles from './Page.styles';
class Page extends Component {
    render() {
        return (
            <Box flex={'fluid'} style={styles.container} testID={this.props.testID}>
                {this.props.children ? this.props.children : <EmText>{this.props.title}</EmText>}
            </Box>
        );
    }
}

export { Page };
