import 'firebase/analytics';
import firebase from 'firebase/app';

import Config from 'react-native-config';

import _ from 'lodash';
import { debug, debugConfigs } from '@emjoy/shared/configs';

import Adjust from '@adjustcom/adjust-web-sdk';

import { adjustEventsMap, isRelease } from './conf';

const Analytics = {
    debug: disable => {
        window?.analytics?.debug(disable === false ? false : true);
    },
    identify: userId => {
        window?.analytics?.identify(userId);
    },
    track: (name, params) => {
        window?.analytics?.track(name, params);
    }
};

// TODO maybe do this with debugConfig ?
// Analytics.debug();

const identifyUser = ({ uid, email }) => {
    if (uid) {
        Analytics.identify(uid);
        adjust.identify(uid);
        firebase.analytics().setUserId(uid);

        window.dataLayer.push({ userId: uid });
        //OneSignal.setExternalUserId(userId);
    }
    if (email) {
        //Tag manager email (snapchat needs it)
        window.dataLayer.push({ userEmail: email });
    }
};

const trackEvent = (name, params = {}) => {
    // Identify user in all available services
    identifyUser(params);

    // We dont want email to be in all events
    if (params.email) {
        delete params.email;
    }

    let eventParams, error;

    if (typeof params === 'object') {
        eventParams = params;
    } else {
        try {
            eventParams = JSON.parse(params);
        } catch (err) {
            error = err;
        }
    }

    eventParams.web = true;
    debugConfigs.trackEvents && debug.log('trackEvent', { name, params, eventParams });

    // Segment Event
    Analytics.track(name, eventParams);

    adjust.trackEvent(name, eventParams);
    // Google Tag Manager
    window.dataLayer.push({ event: name, ...eventParams });

    //Firebase event
    try {
        firebase.analytics().logEvent(name, eventParams || {});
    } catch (firebaseError) {
        // eslint-disable-next-line no-console
        console.log({
            trackEvent: name,
            firebaseError
        });
    }
};

const adjust = {
    setup: () => {
        Adjust.initSdk({
            appToken: Config.ADJUST,
            environment: isRelease ? 'production' : 'sandbox',
            attributionCallback: (e, attribution) => {
                // e: internal event name, can be ignored
                debugConfigs.adjust && debug.log('Adjust attributionCallback', attribution); //TODO what do we do with this?
            },
            logLevel: isRelease ? 'none' : 'verbose'
        });
    },
    identify: userId => {
        Adjust.addGlobalCallbackParameters([{ key: 'user_id', value: userId }]);
        debugConfigs.adjust &&
            debug.log('Adjust.addGlobalCallbackParameters (addSessionCallbackParameter)s', { userId });
    },
    trackEvent: (eventName, params) => {
        if (adjustEventsMap[eventName]) {
            // TODO find out if we need to add more params
            Adjust.trackEvent({ eventToken: adjustEventsMap[eventName] });
            debugConfigs.adjust && debug.log('adjust.trackEvent', eventName, params, adjustEventsMap[eventName]);
        }
    }
};

const setup = () => {
    adjust.setup();
};

export { trackEvent, setup };
