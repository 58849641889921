/* eslint-disable */
import React, { useRef } from 'react';

import _ from 'lodash';
import { Box } from '@mobily/stacks';
import { observer, useLocalObservable } from 'mobx-react';
import { TouchableOpacity } from 'react-native';

import { colors } from '@emjoy/shared/styles';
import { EmImage, EmText } from '@emjoy/shared/primitives/index';

import { reaction } from 'mobx';
import { styles } from './WebQuizRadioButtonGroup.styles';
import { webQuizStore } from '../WebQuiz';
import { webViewStore } from 'src/screens/WebView/WebView';

const selectLeter = index => {
    switch (index) {
        case 0:
            return 'A';
        case 1:
            return 'B';
        case 2:
            return 'C';
        case 3:
            return 'D';
        case 4:
            return 'E';
        default:
            break;
    }
};

const AnswerIcon = ({ index, isWrong, isRight, isResult }) => {
    const source =
        (isRight && 'answerIsRightIcon') || (isWrong && 'answerIsWrongIcon') || (isResult && 'answerIsResultIcon');
    if (source) {
        return <EmImage source={source} width={30} />;
    } else {
        return (
            <EmText color={'neutral.c400'} style={styles.answerIconLetter}>
                {selectLeter(index)}
            </EmText>
        );
    }
};

const WebQuizRadioButton = observer(({ option, onChange, radioButtonGroup, index, percentage }) => {
    const state = useLocalObservable(() => ({
        active: false,
        toogleActive() {
            if (!radioButtonGroup) {
                state.active = !state.active;
            }
            onChange(option);
        }
    }));

    const isWrong = option.active === true;
    const isResult = option.active === false || webQuizStore.onlyShowResults;
    const isRight =
        (_.isBoolean(option.active) && option.points >= 1) || (webQuizStore.onlyShowResults && option.points >= 1);

    return (
        <TouchableOpacity
            accessible={true}
            accessibilityLabel={option.text}
            accessibilityRole={'button'}
            onPress={state.toogleActive}
            disabled={webQuizStore.onlyShowResults || _.isBoolean(option.active)}
        >
            <Box
                padding={1}
                style={[
                    styles.buttonBox,
                    {
                        ...(isResult && { backgroundColor: '#f4f5f9', borderColor: '#f4f5f9' }), //custom color, in design is an opacity but we cant apply here, TBD what we do
                        ...(isWrong && {
                            backgroundColor: colors.primary.default,
                            borderColor: colors.primary.default
                        }),
                        ...(isRight && {
                            backgroundColor: colors.secondary.default,
                            borderColor: colors.secondary.default
                        })
                    }
                ]}
                marginY={1}
            >
                <Box
                    style={[
                        styles.buttonFiller,
                        {
                            width: percentage + '%',
                            ...(isResult && {
                                backgroundColor: colors.secondary.c50,
                                borderColor: colors.secondary.c50
                            }),
                            ...(isWrong && {
                                backgroundColor: colors.primary.c900,
                                borderColor: colors.primary.c900
                            }),
                            ...(isRight && {
                                backgroundColor: colors.secondary.c900,
                                borderColor: colors.secondary.c900
                            })
                        }
                    ]}
                    marginY={1}
                />
                <Box direction={'row'} flex={'fluid'} alignY={'center'}>
                    <Box alignX={'center'} alignY={'center'} marginRight={1} style={styles.buttonRadioCircle}>
                        <Box alignX={'center'} alignY={'center'} style={styles.buttonRadioCircleInner}>
                            <AnswerIcon isWrong={isWrong} isRight={isRight} isResult={isResult} index={index} />
                        </Box>
                    </Box>
                    <Box flex={'fluid'} alignX={'between'} direction={'row'}>
                        <EmText type={'caption'} color={isRight || isWrong ? 'white' : 'secondary.c900'}>
                            {option.text}
                        </EmText>
                        {(isResult || isRight || isWrong) && (
                            <EmText
                                type={'small'}
                                color={isRight || isWrong ? 'white' : 'secondary.c900'}
                                boxProps={{ marginRight: 1 }}
                            >
                                {percentage ? Number(percentage).toFixed() : 0} %
                            </EmText>
                        )}
                    </Box>
                </Box>
            </Box>
        </TouchableOpacity>
    );
});

const WebQuizRadioButtonGroup = observer(({ question, onChange: onChangeParent, percentages }) => {
    const optionsShuffled = useRef(_.shuffle(question.options));

    const { setAnswer } = webQuizStore;
    const state = useLocalObservable(() => ({
        options: optionsShuffled.current.map(option => {
            return {
                ...option,
                active: webQuizStore.userAnswers?.[question.id]?.id === option.id || null
            };
        }),
        chooseSelected(optionId) {
            state.options = optionsShuffled.current.map(option => {
                return {
                    ...option,
                    active: option.id === optionId
                };
            });
        }
    }));

    const onChange = option => {
        state.chooseSelected(option.id);
        setAnswer(option);
    };

    reaction(
        () => question,
        () => (state.options = question.options)
    );

    return (
        <Box style={styles.buttonGroupContainer}>
            {state.options?.map((option, index) => (
                <WebQuizRadioButton
                    key={option.id}
                    option={option}
                    index={index}
                    onChange={onChange}
                    percentage={percentages?.[question.id]?.[option.id]}
                />
            ))}
        </Box>
    );
});

export { WebQuizRadioButtonGroup };
