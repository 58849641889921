import { colors, fontSizes, isSmallSizeFix, sizes, textFonts } from '@emjoy/shared/styles';
import { Platform, StyleSheet } from 'react-native';

const style = StyleSheet.create({
    textInputWraper: {
        borderWidth: 1,
        height: isSmallSizeFix ? sizes.dim4x : sizes.dim6x,
        borderRadius: sizes.dim3x,
        borderColor: colors.secondary.c50
    },
    textInput: {
        ...textFonts.primary,
        fontSize: isSmallSizeFix ? fontSizes.size1 : fontSizes.size3,
        // height: isSmallSizeFix ? sizes.dim4x : sizes.dim6x,
        // ...(isSmallSizeFix && { lineHeight: 0 }),
        padding: 0,
        color: colors.secondary.c900,
        flex: 1,
        position: 'relative',
        paddingHorizontal: sizes.dim2x,
        ...Platform.select({
            web: {
                outline: 'none',
                outlineWidth: 0,
                outlineColor: 'transparent'
            },
            android: {
                fontWeight: 'normal'
            }
        })
    },
    textInputEmpty: {
        ...textFonts.secondary,
        ...Platform.select({
            android: {
                fontWeight: 'normal'
            }
        })
    }
});

export default style;
