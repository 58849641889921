/* eslint-disable */
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import firebase from 'firebase/app';

import { initFirestorter } from 'firestorter';

import { firebaseConfig } from 'configs/firebase.js';

import AppStore from './app';
import { UserStore, userStore } from './user';

import { onboardingStore } from './onboarding';

import { debugConfigs } from '@emjoy/shared/configs';

let firebaseIsSet = false;

const firebaseInit = async () => {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
        initFirestorter({ firebase });
    }

    if (!firebaseIsSet) {
        firebaseSetup();
        firebaseIsSet = true;
    }
};

const firebaseSetup = async () => {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE); // This will force a new user on each refresh for login
    //TODO change it once we get over the onboaridng stage in webapp

    firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
            const {
                displayName,
                email,
                emailVerified,
                isAnonymous,
                metadata,
                phoneNumber,
                photoURL,
                providerData,
                providerId,
                uid
            } = user;

            userStore.setUserData({
                displayName,
                email,
                emailVerified,
                isAnonymous,
                metadata,
                phoneNumber,
                photoURL,
                providerData,
                providerId,
                uid
            });
        } else {
            userStore.resetUserData();
        }
    });
};

firebaseInit();

// Use a local emulator in development
if (debugConfigs.callLocalFunctions) {
    const port = 5001; //make sure port is right
    firebase.app().functions('europe-west1').useEmulator('localhost', port);
}

const appStore = new AppStore();
// const userStore = new UserStore();

export { appStore, userStore, onboardingStore, firebaseInit };
