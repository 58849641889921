import React from 'react';
import { Box } from '@mobily/stacks';
import { Pressable } from 'react-native';

import { observer } from 'mobx-react';

import { EmImage, EmText } from '@emjoy/shared/primitives';

import styles from 'components/goals/index.styles';
import { colors } from 'src/styles';
import { images } from 'styles';

const GoalItemRow = observer(props => {
    return (
        <Box marginY={1} style={[styles.goalItemContainer, props.selected ? styles.goalItemContainerActive : null]}>
            <Pressable
                onPress={props.onPress}
                disabled={props.readOnly}
                accessible={true}
                accessibilityLabel={`Goal ${props.name}.`}
                accessibilityRole={'button'}
                accessibilityState={props.a11yState ? { checked: props.selected } : { checked: null }}
            >
                <Box
                    direction={'row'}
                    paddingY={1}
                    paddingX={2}
                    alignY={'center'}
                    style={styles.goalItemRowWrapper}
                    alignX={'between'}
                >
                    {props.hasIcon && (
                        <EmImage
                            resizeMode={'contain'}
                            fadeDuration={0}
                            source={images.checkedIcon}
                            style={[styles.goalItemIcon, props.selected ? styles.goalItemIconActive : null]}
                        />
                    )}

                    <EmText
                        color={props.selected || props.readOnly ? colors.primary.default : colors.neutral.c800}
                        weight={props.selected || props.readOnly ? 'semibold' : 'regular'}
                    >
                        {props.name}
                    </EmText>
                    <EmImage
                        resizeMode={'contain'}
                        fadeDuration={0}
                        source={props.image}
                        style={[styles.goalItemImage, props.selected ? null : styles.greyImage]}
                        width={64}
                    />
                </Box>
            </Pressable>
        </Box>
    );
});

export { GoalItemRow };
