import { Platform, StyleSheet } from 'react-native';

import { colors, sizes, textStyle } from 'styles';

const isIOS = Platform.OS === 'ios';

const style = StyleSheet.create({
    title: {
        ...textStyle.subtitleBold,
        color: colors.darkGreyBlue
    },
    wrapper: {
        height: sizes.toolbarHeight,
        paddingTop: isIOS ? null : sizes.statusBarHeight,
        width: '100%'
    }
});

export default style;
