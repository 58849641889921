import { StyleSheet } from 'react-native';

import { colors } from '@emjoy/shared/styles';

const wrapperStyle = StyleSheet.create({
    noApp: {
        height: 667,
        width: 375
    },
    withApp: {
        height: 667,
        width: 375,
        borderRadius: 40,
        shadowColor: colors.secondary.c400,
        shadowOffset: {
            width: 0,
            height: 5
        },
        shadowOpacity: 0.2,
        shadowRadius: 24,
        overflow: 'hidden'
    },
    desktopMain: { minHeight: 700 },
    desktopWrapper: {
        maxWidth: 960,
        minWidth: 768,
        minHeight: 667,
        width: '100%'
    },
    safearea: { flex: 1 }
});

export { wrapperStyle };
