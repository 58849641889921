/* eslint-disable */
import firebase from 'firebase/app';
import 'firebase/functions';

import { debugConfigs } from '@emjoy/shared/configs';
import { functionBaseUrl } from 'configs/conf';
import { getUrl } from '@emjoy/shared/libs/axios';

// const functions = firebase.app().functions('europe-west1');

const callFirebaseFunction = async (functionName, data) => {
    try {
        const response = await firebase.app().functions('europe-west1').httpsCallable(functionName)(data);
        return response;
    } catch (error) {
        return { error };
    }
};

// endPoint example: /functionname/
const callFirebaseFunctionEndPoint = async endPoint => {
    try {
        const functionUrl = debugConfigs.callLocalFunctions ? 'http://localhost:' + port : functionBaseUrl;
        const response = await getUrl(functionUrl + endPoint);
        return response;
    } catch (error) {
        return { error };
    }
};

export { callFirebaseFunction, callFirebaseFunctionEndPoint };
