// import { colors } from '@emjoy/shared/styles';
// import { fontSizes, lineHeights, textFonts, textShadow, textStyle } from './typography';
// import { images, svgPaths } from './graphics';
// import { sizes } from './sizes';

// export { colors, sizes, fontSizes, textShadow, textFonts, images, svgPaths, textStyle, lineHeights };

import { Platform } from 'react-native';

import { colors } from '@emjoy/shared/styles';
import { fontSizes, lineHeights, textFonts, textShadow, textStyle } from './typography';
import { images, svgPaths } from './graphics';
import { isSmallSizeFix, sizes } from './sizes';

const isIOS = Platform.OS === 'ios';

const texts = {
    duration: {
        ...textStyle.number,
        letterSpacing: 0.5,
        color: colors.secondaryDark,
        textAlign: 'right'
    },
    logo: {
        ...textFonts.primary,
        color: colors.coral,
        fontSize: fontSizes.size34,
        letterSpacing: 0,
        textAlign: 'center'
    },
    display: {
        ...textStyle.displayBold,
        color: colors.white,
        textAlign: 'center',
        marginBottom: sizes.dim1x
    },
    sectionHeader: {
        ...textStyle.headlineBold,
        color: colors.darkGreyBlue,
        height: sizes.dim4x,
        margin: sizes.dim2x,
        marginBottom: 0
    },
    sectionTitle: {
        ...textStyle.titleBold,
        color: colors.darkGreyBlue,
        height: sizes.dim4x,
        margin: sizes.dim2x,
        marginBottom: 0,
        paddingTop: sizes.dim1x
    },
    categoryTitle: {
        ...textFonts.primary,
        ...textShadow,
        bottom: 0,
        color: colors.white,
        fontSize: fontSizes.m,
        letterSpacing: 1,
        margin: sizes.dim1x,
        position: 'absolute'
    },
    categoryHeaderTitle: {
        ...textStyle.headlineBold,
        ...textShadow,
        bottom: 0,
        color: colors.white,
        letterSpacing: 1,
        marginHorizontal: sizes.dim2x
    },
    categoryDescription: {
        ...textStyle.contentTitleMedium,
        color: colors.darkGreyBlue80,
        letterSpacing: (0.5 * sizes.screenWidth) / sizes.BASE_SCREEN_WIDTH,
        lineHeight: (20 * sizes.screenWidth) / sizes.BASE_SCREEN_WIDTH,
        margin: sizes.dim2x
    },
    authorName: {
        ...textStyle.contentTitleBold,
        color: colors.darkGreyBlue,
        letterSpacing: 0.5,
        marginTop: sizes.dim1x,
        textAlign: 'center'
    },
    authorPosition: {
        ...textStyle.descriptionSmallMedium,
        color: colors.steel,
        letterSpacing: 0.2
    },
    sessionRowTitle: {
        ...textStyle.contentTitleBold,
        color: colors.darkGreyBlue,
        letterSpacing: 0.5,
        marginLeft: sizes.dim1x
    },
    sessionRowWideTitle: {
        ...textStyle.titleBold,
        color: colors.darkGreyBlue,
        letterSpacing: 0.5,
        marginLeft: sizes.dim1x
    },
    sessionRowDuration: {
        ...textFonts.system,
        color: colors.steel,
        fontSize: fontSizes.s,
        lineHeight: 21,
        textAlign: 'right',
        width: 5 * sizes.dim1x
    },
    sessionDetailsTitle: {
        ...textStyle.subtitleBold,
        color: colors.darkGreyBlue,
        lineHeight: 27,
        textAlign: 'center'
    },
    sessionDetailsDuration: {
        ...textFonts.system,
        color: colors.darkGreyBlue,
        fontSize: fontSizes.s,
        lineHeight: 21,
        textAlign: 'center'
    },
    sessionDetailsDescription: {
        ...textStyle.descriptionLargeMedium,
        color: colors.darkGreyBlue80,
        letterSpacing: (0.5 * sizes.screenWidth) / sizes.BASE_SCREEN_WIDTH,
        margin: sizes.dim1x
    },
    secondaryDetails: {
        ...textStyle.disclaimerMedium,
        color: colors.darkGreyBlue60,
        margin: sizes.dim1x
    },

    tabLabel: {
        ...textStyle.descriptionSmallBold,
        lineHeight: 14,
        letterSpacing: 0,
        textAlign: 'center'
    },
    tagTitle: {
        ...textStyle.descriptionSmallBold,
        textAlign: 'center',
        color: colors.darkGreyBlue
    },
    buttonTitle: {
        ...textStyle.contentTitleBold,
        color: colors.coral,
        height: sizes.dim3x
    },
    buttonSwitchHome: {
        ...textStyle.contentTitleMedium,
        color: colors.secondaryDark,
        letterSpacing: 1,
        marginBottom: 0
    }
};

const defaultTheme = {
    tab: {
        label: texts.tabLabel,
        icon: { width: 24, height: 24 }
    },
    screenBody: {
        flex: 1,
        padding: sizes.dim2x,
        paddingTop: sizes.dim2x + (isIOS ? 20 : 0)
    },
    screenBodyNoPadding: {
        flex: 1
    },
    categoryWrapper: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: sizes.dim2x
    },

    categoryTagSlider: {
        height: isIOS ? 80 : 80 + sizes.statusBarHeight,
        paddingHorizontal: sizes.dim05x,
        paddingTop: isIOS ? 0 : sizes.statusBarHeight
    },
    contentCategoryTagSlider: {
        alignItems: 'flex-end',
        paddingBottom: sizes.dim2x,
        paddingHorizontal: sizes.dim1x
    },
    tag: {
        wrapper: {
            height: 36,
            borderRadius: 20,
            backgroundColor: colors.white,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: colors.paleBlue,
            paddingHorizontal: sizes.dim2x,
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 72,
            marginHorizontal: sizes.dim05x,
            flexDirection: 'row'
        },
        wrapperSelected: {
            backgroundColor: colors.coral,
            borderColor: colors.coral,
            borderStyle: 'solid',
            borderWidth: 1
        },
        title: texts.tagTitle,
        titleSelected: {
            color: colors.white
        },
        icon: {
            width: sizes.dim3x,
            height: sizes.dim3x
        }
    },
    textButton: {
        title: texts.buttonTitle,
        wrapper: {
            height: 32,
            justifyContent: 'center',
            alignItems: 'center'
        }
    },

    pageTitle: texts.sessionDetailsTitle
};

export {
    defaultTheme,
    colors,
    sizes,
    texts,
    textFonts,
    fontSizes,
    images,
    textStyle,
    textShadow,
    lineHeights,
    svgPaths,
    isSmallSizeFix
};
