// import { getLocales } from 'react-native-localize';
import { sprintf } from 'sprintf-js';

// let languageCode = getLocales()[0].languageCode;
let languageCode = 'en';

const translations = {
    en: require('locale/en/lang.json')
};

const selectedLanguage = translations[languageCode] || translations.en;
const selectedLanguageCode = translations[languageCode] ? languageCode : 'en';

const L = (term, ...data) => {
    if (!translations.en[term]) {
        // eslint-disable-next-line no-console
        __DEV__ && console.warn(`Translation "${term}" not found!`);
    }
    return sprintf.call(null, selectedLanguage[term] || translations.en[term] || term, ...data);
};

export { L, selectedLanguageCode };
