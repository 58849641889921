import React, { useMemo, useRef } from 'react';

import _ from 'lodash';
import { Box } from '@mobily/stacks';
import { Image as RNImage } from 'react-native';

import { getColor } from '@emjoy/shared/utils/getColor';
import { images } from 'styles';

const TintedImage = ({ source, width, height, tintColor, style }) => {
    const id = useMemo(() => {
        return _.random(0, 1000000);
    }, []);
    React.useEffect(() => {
        function draw() {
            // draw image
            ctx.drawImage(this, 0, 0, width, height);
            // set composite mode
            ctx.globalCompositeOperation = 'source-in';
            // draw color
            ctx.fillStyle = tintColor;
            ctx.fillRect(0, 0, width, height);
        }
        var img = new Image();
        img.onload = draw;
        img.src = source;

        var c = document.getElementById(`c_${id}`);
        var ctx = c.getContext('2d');
    });

    return (
        <Box style={style}>
            <canvas id={`c_${id}`} width={width} height={height} />
        </Box>
    );
};
// This for web need to load width/height auto, '24' in width is just a number to have something ftm
const EmImage = ({ boxProps, source, tintColor, width = 24, height, style, ...rest }) => {
    const dimensions = {
        width,
        height: height || width
    };

    const getSource = _.includes(source, 'http') ? { uri: source } : _.get(images, source);
    const getTintColor = tintColor ? (_.includes(tintColor, '#') ? tintColor : getColor(tintColor)) : style?.color;

    return (
        <Box {...boxProps}>
            {!tintColor ? ( //RNW bug with safari on ios
                <RNImage {...rest} source={getSource} style={[dimensions, style, { tintColor: getTintColor }]} />
            ) : (
                <TintedImage
                    {...rest}
                    source={getSource}
                    width={dimensions.width}
                    height={dimensions.height}
                    tintColor={getTintColor}
                    style={[dimensions, style]}
                />
            )}
        </Box>
    );
};

export { EmImage };
