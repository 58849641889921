import { StyleSheet } from 'react-native';

import { colors } from '@emjoy/shared/styles';

const checkStyles = StyleSheet.create({
    wrapperInactive: {
        width: 40,
        height: 40,
        borderRadius: 20,
        borderWidth: 1,
        borderColor: colors.neutral.c100,
        backgroundColor: colors.white,
        shadowColor: colors.neutral.c100,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 1,
        shadowRadius: 6
    },
    wrapperActive: {
        width: 40,
        height: 40,
        borderRadius: 20,
        borderWidth: 1,
        borderColor: colors.primary.default,
        backgroundColor: colors.white
    },
    ligatureActive: {
        width: 4,
        backgroundColor: colors.primary.default
    },
    ligatureInactive: {
        width: 4,
        backgroundColor: colors.neutral.c100
    },
    checkImage: {
        width: 12,
        height: 12
    },
    currentContent: {
        width: 32,
        height: 32,
        borderRadius: 16,
        backgroundColor: colors.primary.default
    },
    stepTitle: { height: 40 }
});

export { checkStyles };
