const colors = {
    primary: {
        default: '#C23077',
        c900: '#852863',
        c800: '#AC2D70',
        c700: '#C23077',
        c600: '#D9337E',
        c500: '#EB3583',
        c400: '#EB5097',
        c300: '#ED6EAC',
        c200: '#F097C4',
        c100: '#F4BFDB',
        c50: '#F9EAF1'
    },
    secondary: {
        default: '#535CAA',
        c900: '#101666',
        c800: '#1F277B',
        c700: '#273187',
        c600: '#2F3A92',
        c500: '#35419B',
        c400: '#535CAA',
        c300: '#7079B8',
        c200: '#989ECC',
        c100: '#C0C4E1',
        c50: '#E6E7F3'
    },
    neutral: {
        c800: '#888BB3',
        c400: '#C0C4E1',
        c100: '#E6E7F3'
    },
    black: '#000000',
    white: '#FFFFFF',
    white0: '#ffffff00',
    white10: '#FFFFFF1A',
    white24: '#FFFFFF3D',
    white30: '#FFFFFF4d',
    white40: '#FFFFFF66',
    white50: '#FFFFFF80',
    shadows: {
        black20: '#00000033',
        black40: '#00000066'
    },
    white72: '#FFFFFFB8',
    overlay: {
        popup: '#0000008f',
        session: '#0000003d'
    },
    sso: {
        facebook: '#3B5996',
        google: '#DC4E41',
        apple: '#000000'
    },
    transparent: 'transparent',
    chatTherapists: '#B90063',
    searchBar: '#EC097F',
    grayWhite: '#F9F9FC'
};

// TBD shadows to be added:

// Shadow-large: 0px 5px 20px rgba (83, 92, 170, 0.15)
// Shadow-small: 0px 0px 5px rgba (83, 92, 170, 0.10)
// Shadow-stroke: Box-shadow: 0px 0px 5px rgba (83, 92, 170, 0.10); Stroke: #E6E7F3

// TBD overlays to be added

// Overlay locked: opacity: 0,6; rgba ( 0, 0, 0, 0.56);
// Overlay images: opacity variations: 0.2 / 0.4 / 0.6 / 0.8 (-225deg, rgba(0,0,0,0.00) 0%, #000000 100%);

export { colors };
