import { StyleSheet } from 'react-native';

import { colors } from '@emjoy/shared/styles';

const style = StyleSheet.create({
    dots: {
        width: 2 * 2,
        height: 2 * 2,
        borderRadius: 2,
        backgroundColor: colors.neutral.c800,
        marginRight: 6
    },
    dotsLarge: {
        width: 10 * 2,
        height: 2 * 2,
        borderRadius: 2,
        backgroundColor: colors.primary.default,
        marginRight: 5
    },
    dotsPrevious: {
        backgroundColor: colors.primary.default
    },
    dotsBgTransparent: {
        backgroundColor: 'transparent'
    },
    hideDot: {
        display: 'none'
    }
});

export default style;
