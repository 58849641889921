import _ from 'lodash';

import 'firebase/functions';
import 'firebase/storage';
import firebase from 'firebase/app';

import axios from 'axios';
import { Collection } from 'firestorter';
import { computed, makeObservable, observable, reaction, when } from 'mobx';
import { userStore } from './';

import { debugConfigs } from '@emjoy/shared/configs';
class Store {
    constructor() {
        makeObservable(this, {
            goals: observable,
            controlFeed: observable,
            launchReferralCodes: observable,
            timestamp: computed
        });

        when(
            () => {
                return _.find(this.controlFeedCollection?.docs, { id: 'publish' })?.data?.lastPublished?.timestamp;
            },
            () => {
                // console.warn('when controlFeedCollection');
                const { docs } = this.controlFeedCollection;
                this.controlFeed = {
                    publish: _.find(docs, { id: 'publish' })?.data,
                    appSettings: _.find(docs, { id: 'appSettings' })?.data
                };
            }
        );
    }

    controlFeedCollection = new Collection('controlFeed', { mode: 'auto', debug: debugConfigs.firestorter });
    featureFlagsCollection = new Collection('controlFeed/appSettings/featureFlags', { mode: 'on' });

    goals = [];
    shuffledGoals = [];
    sortedGoals = [];

    launchReferralCodes = {
        type: null,
        code: null
    };
    controlFeed = {};

    getDataReaction = reaction(
        () => {
            return _.find(this.controlFeedCollection?.docs, { id: 'publish' })?.data?.lastPublished?.timestamp;
        },
        () => {
            // console.warn('reaction controlFeedCollection');
            const { docs } = this.controlFeedCollection;

            this.controlFeed = {
                publish: _.find(docs, { id: 'publish' })?.data,
                appSettings: _.find(docs, { id: 'appSettings' })?.data
            };
        }
    );

    get timestamp() {
        return this.controlFeed.publish?.lastPublished.timestamp;
    }

    get featureFlags() {
        const { docs } = this.featureFlagsCollection;
        return _.map(docs, 'data');
    }

    init = async () => {
        this.controlFeedCollection.query = userStore.userDocument.id ? undefined : null;
        this.initialized = true;
    };

    getData = async () => {
        if (this.gettingData) {
            //don't start a new getData if we have one running already
            this.dataReady = true;
            return;
        }

        this.gettingData = true;

        const ref = firebase.storage().ref(`json/data_${this.timestamp}.json`);

        try {
            const url = await ref.getDownloadURL();
            const res = await axios.get(url);
            this.allContent = res.data;
            this.goals = _.pickBy(this.allContent.goals);
            this.sortedGoals = _.sortBy(this.goals, 'order');
            this.shuffledGoals = _.shuffle(this.goals);
        } catch (error) {
            // console.log('getData', error); TODO catch the error and notify the user
        }

        this.dataReady = true; // TODO catch the error and notify the user
        this.gettingData = false;
    };
}

export default Store;
