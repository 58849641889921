import { colors } from '@emjoy/shared/styles';
import { sizes, textStyle } from 'styles';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        // justify  Content: 'flex-end',
        backgroundColor: colors.overlay.popup
    },
    hidden: {
        left: -10000
    },
    wrapper: {
        // flex: 1,
        backgroundColor: colors.white,
        borderRadius: sizes.dim1x
    },
    wrapperDesktop: {
        alignSelf: 'center',
        width: 500
    },
    title: {
        ...textStyle.subtitleBold,
        textAlign: 'center',
        color: colors.darkGreyBlue
    },
    message: {
        ...textStyle.descriptionSmallMedium,
        textAlign: 'center',
        color: colors.darkGreyBlue,
        marginTop: sizes.dim2x
    },
    container: {
        width: '100%',
        height: '100%'
        // paddingTop: scale >= 1 ? 150 : 50
    },
    button: {
        width: '100%',
        height: sizes.dim4x
    }
});

export default styles;
