import React from 'react';
import { Image, Text } from 'react-native';

import { Box, Column, Columns, Stack } from '@mobily/stacks';

import { EmText } from '@emjoy/shared/primitives';
import { L } from 'libs/i18n';

import { checkStyles } from './Caption.styles';

import { colors } from '@emjoy/shared/styles';
import { images } from 'styles';

const captionSteps = [
    {
        title: L('webappCaptionStep1Title'),
        description: L('webappCaptionStep1Description')
    },
    {
        title: L('webappCaptionStep2Title'),
        description: L('webappCaptionStep2Description')
    },
    // { // we kill the paywall for now
    //     title: L('webappCaptionStep3Title'),
    //     description: L('webappCaptionStep3Description')
    // },
    // { // we kill the paywall for now
    //     title: L('webappCaptionStep4Title'),
    //     description: L('webappCaptionStep4Description')
    // },
    {
        title: L('webappCaptionStep5Title'),
        description: L('webappCaptionStep5Description')
    }
];

const lastStep = captionSteps.length - 1;

const CaptionSections = ({ currentPosition }) => {
    const content = captionSteps.map(({ title, description }, index) => {
        return (
            <Columns space={2}>
                <Column width={'content'} style={{ flex: 1 }}>
                    <Box alignX={'center'} alignSelf={'right'} flex={'fluid'}>
                        <Box
                            alignX={'center'}
                            alignY={'center'}
                            style={index <= currentPosition ? checkStyles.wrapperActive : checkStyles.wrapperInactive}
                        >
                            {index <= currentPosition && (
                                <Box alignX={'center'} alignY={'center'} style={checkStyles.currentContent}>
                                    <EmText color={colors.white}>
                                        {index < currentPosition || currentPosition === 2 ? (
                                            <Image
                                                source={images.webapp.onboardingCheck}
                                                style={checkStyles.checkImage}
                                            />
                                        ) : (
                                            index + 1
                                        )}
                                    </EmText>
                                </Box>
                            )}

                            {index > currentPosition && (
                                <EmText style={{ color: colors.neutral.c100 }}>{index + 1}</EmText>
                            )}
                        </Box>

                        {index < lastStep && (
                            <Box flex={'fluid'}>
                                {index <= currentPosition && <Box flex={'fluid'} style={checkStyles.ligatureActive} />}

                                {index >= currentPosition && (
                                    <Box flex={'fluid'} style={checkStyles.ligatureInactive} />
                                )}
                            </Box>
                        )}
                    </Box>
                </Column>
                <Column>
                    <Stack space={1}>
                        <Box alignY={'center'} style={checkStyles.stepTitle}>
                            <EmText type={'h4'} color={currentPosition !== index ? colors.neutral.c400 : null}>
                                {title}
                            </EmText>
                        </Box>
                        <Box marginBottom={3}>
                            {currentPosition === index && (
                                <EmText type="normal" weight={'regular'}>
                                    {description}
                                </EmText>
                            )}
                        </Box>
                    </Stack>
                </Column>
            </Columns>
        );
    });

    return (
        <Box flex={'fluid'} paddingTop={5}>
            {content}
        </Box>
    );
};

const Caption = ({ currentPosition, isNewUser }) => {
    const title =
        currentPosition === lastStep
            ? isNewUser
                ? L('webappCaptionTitleDownload')
                : L('webappCaptionTitleDownloadReturningUser')
            : L('webappCaptionTitleStart');

    return (
        <Box flex={'fluid'} paddingTop={4}>
            <EmText type={'h1'}>{title}</EmText>
            <CaptionSections currentPosition={currentPosition} />
        </Box>
    );
};

export { Caption, CaptionSections, captionSteps };
