/* eslint-disable */
import React, { Component } from 'react';
import { Box } from '@mobily/stacks';
import { ScrollView } from 'react-native';

import { EmButton } from '@emjoy/shared/primitives';
import { GoalsSelector } from 'components/goals';
import { L } from 'libs/i18n';
import { Page } from 'components/utils';

class GoalsPage extends Component {
    goalsSelector = React.createRef();

    onContinue = () => {
        this.props.onNext('goals', this.goalsSelector);
    };

    render() {
        return (
            <Page>
                <Box flex={'fluid'}>
                    <ScrollView
                        bounces={false}
                        scrollEnabled={true}
                        showsVerticalScrollIndicator={true}
                        style={{ height: 0 }} //TODO Why is this working and nothing else?
                    >
                        <GoalsSelector
                            ref={ref => (this.goalsSelector = ref)}
                            boxProps={{ marginTop: 4, paddingBottom: 2 }}
                            navigation={this.props.navigation}
                        />
                    </ScrollView>
                </Box>

                <EmButton
                    onPress={this.onContinue}
                    title={L('continue')}
                    accessibilityLabel={L('continue')}
                    accessibilityHint={L('continue')}
                    boxProps={{ marginX: 3 }}
                    paddingY={1.5}
                />
            </Page>
        );
    }
}

export { GoalsPage };
