/* eslint-disable */
import { Linking, Platform } from 'react-native';

import _ from 'lodash';
// import appleAuth from '@invertase/react-native-apple-authentication';
import Config from 'react-native-config';

import { appInfo } from './utils';
import { authErrorsManagement, emails, ids } from './constants';

import { L } from './i18n';

const isIOS = Platform.OS === 'ios';

const isRelease = Config.APP_TARGET === 'release';

// const supportsAppleSignIn = appleAuth.isSupported;

const configuration = {
    apiKeys: {
        segmentio: Config.SEGMENT
    },
    supportEmail: emails.support
};

const adjustEventsMap = {
    //TODO this should be in the shared config
    signup: 'lbnok2',
    mktPermissionsScreenOnboarding: '4ai6xb',
    buySubscriptionIntent: 'p68ibh',
    checkAppTransparencyPerimission: 'kqa71i',
    answerAppTransparencyPerimission: 'kx4xz9',
    appOpened: 'aafz50'
};

const interactions = {
    apiDelay: 500,
    keyboardDelay: 250
};

const defaultConfidenceQuestions = [
    { id: ids.confidenceSelectorIds.not, name: 'I Want The Basics' },
    { id: ids.confidenceSelectorIds.okay, name: 'I Know My Way Around' },
    { id: ids.confidenceSelectorIds.very, name: 'I Want To Level Up' }
];

const defaultYesNo = [
    { id: ids.partnerSelectorIds.yes, name: L('yes') },
    { id: ids.partnerSelectorIds.no, name: L('no') }
];

const defaultReferralCodeProps = {
    referralType: null,
    userId: null,
    active: true,
    deactivateReason: null
};

const defaultReferralProps = {
    referralCode: null,
    referralType: null,
    referralSuccess: false,
    referredBy: [],
    referredTo: []
};

const authErrors = {
    [authErrorsManagement.weakPassword]: L('please_password_6_long'),
    [authErrorsManagement.invalidEmail]: L('please_use_valid_email'),
    [authErrorsManagement.emailInUse]: L('email_already_in_use'),
    [authErrorsManagement.accountExists]: L('different_method_to_login'),
    [authErrorsManagement.userNotFound]: L('user_not_registered'),
    [authErrorsManagement.wrongPassword]: L('wrong_username_or_password')
};

const getSupportEmailMessage = appInfoData => {
    const appInfoText = _.reduce(
        appInfoData,
        function (result, value, key) {
            return (result += _.startCase(key) + ': ' + value + '\n');
        },
        ''
    );
    return `mailto:${configuration.supportEmail}?subject=${L(
        'emjoy_support'
    )}&body=${`\n\n\n----------\n\n${appInfoText}`}`;
};

const getStoreReviewEmailMessage = appInfoData => {
    const appInfoText = _.reduce(
        appInfoData,
        function (result, value, key) {
            return (result += _.startCase(key) + ': ' + value + '\n');
        },
        ''
    );
    return `mailto:${configuration.supportEmail}?subject=${L(
        'emjoy_store_review_support'
    )}&body=${`\n\n\n----------\n\n${appInfoText}`}`;
};

const getSettingsSupportItem = supportEmailMessage => {
    return {
        id: ids.settingsIds.support,
        action: () => {
            Linking.openURL(supportEmailMessage);
        },
        name: L('support'),
        order: 1000,
        type: 'screen'
    };
};

const notSameUserText = isIOS ? L('logged_in_text_ios') : L('logged_in_text_android');

const userFeedbackCampaignId = 'inAppUserFeedback3';

const defaultSessionHotSpots = [
    {
        percentage: 15,
        name: 'intro',
        isDefault: true
    },
    {
        percentage: 30,
        name: 'rising',
        isDefault: true
    },
    {
        percentage: 50,
        name: 'highpoint',
        isDefault: true
    },
    {
        percentage: 75,
        name: 'fallingaction',
        isDefault: true
    }
];

const sessionPreviewUrl = 'https://app.letsemjoy.com/p/session/';

export {
    appInfo,
    authErrors,
    configuration,
    defaultConfidenceQuestions,
    defaultReferralCodeProps,
    defaultReferralProps,
    defaultSessionHotSpots,
    defaultYesNo,
    getSettingsSupportItem,
    getStoreReviewEmailMessage,
    getSupportEmailMessage,
    interactions,
    isIOS,
    isRelease,
    adjustEventsMap,
    notSameUserText,
    sessionPreviewUrl,
    // supportsAppleSignIn,
    userFeedbackCampaignId
};
