import React from 'react';

import _ from 'lodash';
import { Box } from '@mobily/stacks';
import { createIconSetFromIcoMoon } from 'react-native-vector-icons';

// Only difference between Mobile (.native) and WebApp (.web) is this json import
import icoMoonConfig from '@emjoy/webapp/src/assets/fonts/selection.json';
import { EmIconProps } from './EmIcon.types';
import { getColor } from '@emjoy/shared/utils/getColor';

const Icon = createIconSetFromIcoMoon(icoMoonConfig);

const EmIcon = (props: EmIconProps) => {
    const { name, size, color, boxProps } = props;

    const iconColor = getColor(color);

    return (
        <Box {...boxProps}>
            <Icon name={name} size={size} color={iconColor} />
        </Box>
    );
};

export { EmIcon };
