import React from 'react';
import { Text } from 'react-native';

import _ from 'lodash';
import { Box } from '@mobily/stacks';

import { getColor } from '@emjoy/shared/utils/getColor';
import { htmlToString } from '@emjoy/shared/libs';

import stylesFn from './EmText.styles';
import { EmTextProps } from './EmText.types';

const EmText = (props: EmTextProps) => {
    const {
        children,
        boxProps,
        style,
        color,
        type = 'normal',
        textAlign,
        html,
        numberOfLines,
        ellipsizeMode,
        testID,
        noBox, // allows to correctly align multiple EmTexts
        onPress
    } = props;

    // Get text color
    const DEFAULT_TEXT_COLOR = 'secondary.c900';
    const selectColor = color || style?.color || DEFAULT_TEXT_COLOR;
    const textColor = getColor(selectColor);

    // Get content from children (could be HTML)
    const content = html ? htmlToString(children) : children;

    // Compute text style combining default text with custom style type
    const styles = stylesFn(props);
    const computedTextStyle = _.compact([styles.text, styles[type], style, { color: textColor }]);

    return noBox ? (
        <Text numberOfLines={numberOfLines} ellipsizeMode={ellipsizeMode} onPress={onPress} style={computedTextStyle}>
            {content}
        </Text>
    ) : (
        <Box alignY={'center'} alignX={textAlign} testID={testID} {...boxProps}>
            <Text
                numberOfLines={numberOfLines}
                ellipsizeMode={ellipsizeMode}
                onPress={onPress}
                style={computedTextStyle}
            >
                {content}
            </Text>
        </Box>
    );
};

export { EmText };
