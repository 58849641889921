import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';

import { AlertScreen } from 'screens/onboarding/AlertScreen';
import { OnboardingScreen } from 'screens/onboarding/OnboardingScreen';
import { SuccessScreen } from 'screens/onboarding/SuccessScreen';

// import { StripeScreen } from 'screens/onboarding/StripeScreen';

import { appStore, onboardingStore, userStore } from 'stores';
import { WebQuiz } from './pages/WebQuiz/WebQuiz';

import { configure } from 'mobx';

import { colors } from 'styles';

configure({
    enforceActions: 'never'
    // computedRequiresReaction: true,
    // reactionRequiresObservable: true,
    // observableRequiresReaction: true,
    // disableErrorBoundaries: true
});

const { Navigator, Screen } = createStackNavigator();

const backDisabledConfig = {
    gestureEnabled: false
};

const fadeConfig = {
    cardOverlayEnabled: true,
    cardStyle: { backgroundColor: 'transparent' },
    presentation: 'transparentModal'
    // transitionSpec: { //TODO  disabel for now, the animation is not smooth, at least in dev mode
    //     open: {
    //         config: { duration: 75 },
    //         animation: 'timing'
    //     },
    //     close: {
    //         config: { duration: 50 },
    //         animation: 'timing'
    //     }
    // },
    // cardStyleInterpolator: ({ current: { progress } }) => ({
    //     cardStyle: {
    //         opacity: progress.interpolate({
    //             inputRange: [0, 0.5, 0.9, 1],
    //             outputRange: [0, 0.25, 0.7, 1]
    //         })
    //     }
    // })
};

const FixTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: colors.white
    }
};

const linking = {
    config: {
        screens: {
            Onboarding: '',
            SexToyQuiz: 'sextoyquiz'
        }
    }
};

function App() {
    return (
        <NavigationContainer theme={FixTheme} linking={linking}>
            <Navigator
                initialRouteName="Onboarding"
                // mode={'modal'} //TODO we might need to move it to a root navigator?
                screenOptions={{
                    headerShown: false
                }}
            >
                <Screen name="Onboarding" component={OnboardingScreen} />
                {/* <Screen name="Stripe" component={StripeScreen} /> */}
                <Screen name="Alert" component={AlertScreen} options={{ ...backDisabledConfig, ...fadeConfig }} />
                <Screen name="Success" component={SuccessScreen} options={{ ...backDisabledConfig, ...fadeConfig }} />
                <Screen name="SexToyQuiz" component={WebQuiz} testId={'sexToyQuiz'} />
            </Navigator>
        </NavigationContainer>
    );
}

export { App };
