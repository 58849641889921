import React from 'react';
import { StyleSheet, Pressable } from 'react-native';

import _ from 'lodash';
import { Box } from '@mobily/stacks';

import { EmActivityIndicator } from '../EmActivityIndicator/EmActivityIndicator';
import { EmTextIcon } from '../EmTextIcon/EmTextIcon';

import stylesFn from './EmButton.style';
import { EmButtonProps } from './EmButton.types';
import { sizes } from '@emjoy/shared/styles';

const EmButton = (props: EmButtonProps) => {
    const {
        title,
        type,
        textType,
        style,
        textStyle,
        boxProps,
        disabled,
        isBusy,
        small,
        minMargin,
        paddingX,
        paddingY,
        testID,
        accessibilityLabel,
        accessibilityHint,
        iconRight,
        iconLeft,
        iconSize,
        iconSpace,
        iconColor,
        emTextProps,
        onPress
    } = props;

    // Compute button style combining default button with custom style type
    const styles = stylesFn(props);
    const buttonStyle = _.compact([styles.button, styles[type + 'Button'], style]);
    const titleStyle = StyleSheet.flatten([styles.buttonText, styles[type + 'Text'], textStyle]);

    const ICON_SIZE_DEFAULT = sizes.dim25x;

    // we use the ZWS (zero-width space) ascii character to keep button height when activity indicator is displayed
    const EMPTY_TEXT = '​';

    return (
        <Box marginY={small ? 1 : minMargin ? 0.5 : 2} {...boxProps}>
            <Pressable
                accessibilityRole={'button'}
                accessibilityHint={accessibilityHint}
                accessibilityLabel={accessibilityLabel}
                testID={testID}
                disabled={!!(isBusy || disabled)}
                onPress={onPress}
                style={buttonStyle}
            >
                {isBusy && <EmActivityIndicator color={titleStyle.color} />}
                <EmTextIcon
                    iconSize={iconSize || ICON_SIZE_DEFAULT}
                    iconRight={isBusy ? null : iconRight}
                    iconLeft={isBusy ? null : iconLeft}
                    iconColor={iconColor || titleStyle.color}
                    iconSpace={iconSpace}
                    boxProps={title && { paddingX: paddingX, paddingY: paddingY ? paddingY : small ? 0.5 : 1 }}
                    emTextProps={{
                        style: titleStyle,
                        type: textType ? textType : small ? 'small' : 'h4',
                        testID: 'buttonTitle',
                        ...emTextProps
                    }}
                >
                    {isBusy ? EMPTY_TEXT : title}
                </EmTextIcon>
            </Pressable>
        </Box>
    );
};

export { EmButton };
