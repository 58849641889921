import { StyleSheet } from 'react-native';

import { colors } from 'styles';

const MobileDownloadStyle = StyleSheet.create({
    downloadSecondaryText: { color: colors.steel },
    downloadBadge: { width: 162, height: 48 }
});

export { MobileDownloadStyle };
