import { Linking } from 'react-native';

const getDownloadLinks = async platform => {
    const initialUrl = await Linking.getInitialURL();
    const platforms = ['android', 'ios', 'store'];

    let utms = {};
    initialUrl
        .replace('https://app.letsemjoy.com', '')
        .replace(/^\?/, '')
        .split('&')
        .map(x => x.split('='))
        .map(x => {
            utms[x[0]] = x[1] || null;
        });

    let adjUrl = `https://b2pr.adj.st/deeplink/today/?adj_t=cs68v18&adj_campaign=${utms.utm_campaign}&adj_adgroup=${utms.utm_content}&adj_creative=${utms.utm_term}`;

    const urls = {};

    platforms.forEach(function (id) {
        urls[id] = adjUrl;
    });

    return urls;
};

export { getDownloadLinks };
