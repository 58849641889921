import _ from 'lodash';
import React from 'react';
import { Box } from '@mobily/stacks';

import { isIOS } from '@libs/conf';

import styles from './DotsWrapper.styles';

const Dot = ({ currentIndex, index, dontShowFirst, dontShowLast, dataLength }) => {
    const hideFirstDot = dontShowFirst && index === 0;
    const hideLastdot = dontShowLast && index === dataLength - 1;

    return (
        <Box
            style={[
                hideFirstDot && styles.hideDot,
                hideLastdot && styles.hideDot,
                styles.dots,
                index === currentIndex && styles.dotsLarge,
                index < currentIndex && styles.dotsPrevious
            ]}
        />
    );
};

const DotsWrapper = props => {
    const { dataLength, currentIndex, dontShowFirst, dontShowLast } = props;
    const hideDotsFirstPage = dontShowFirst && currentIndex === 0;
    const hideDotsLastPage = dontShowLast && currentIndex === dataLength - 1;

    const renderDots = () => {
        let dots = [];
        for (let i = 0; i < dataLength; i++) {
            dots.push(<Dot key={`indicatorDot_${i}`} index={i} currentIndex={currentIndex} {...props} />);
        }
        return dots;
    };

    return (
        <Box direction={'row'} alignX={'center'} marginTop={isIOS ? 0 : 1}>
            {hideDotsFirstPage || (!hideDotsFirstPage && hideDotsLastPage) ? <Box paddingTop={0.5} /> : renderDots()}
        </Box>
    );
};

export { DotsWrapper };
