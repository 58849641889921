import React from 'react';
import { ActivityIndicator } from 'react-native';
import { Box } from '@mobily/stacks';

function EmActivityIndicator({ color, boxProps }) {
    return (
        <Box {...boxProps}>
            <ActivityIndicator size="small" color={color} />
        </Box>
    );
}
export { EmActivityIndicator };
