import _ from 'lodash';
import { Platform, StyleSheet } from 'react-native';

import { colors } from '@emjoy/shared/styles';
import { sizes } from 'styles';

const scale = sizes.screenWidth / 375;

const styles = _.memoize(props => {
    const { shadow } = props;
    return StyleSheet.create({
        mainContainer: {
            width: scale >= 1 ? props.width * scale : props.width, //TODO this is bad, we should make EmCard smarter
            //right now it takes the scale in account in a bad way and sometimes it needs to be passed a width/scale param
            height: props.height * scale + sizes.dim3x
        },
        shadowContainer: {
            ...Platform.select({
                ios: {
                    shadowColor: shadow?.shadowColor || colors.secondary.c400,
                    shadowOffset: {
                        width: shadow?.shadowOffset?.width || 0,
                        height: shadow?.shadowOffset?.height || 5
                    },
                    shadowOpacity: shadow?.shadowOpacity || 0.2,
                    shadowRadius: shadow?.shadowRadius || 8
                },
                android: {
                    height: props.height * scale,
                    marginBottom: sizes.dim2x,
                    borderColor: colors.neutral.c100,
                    borderWidth: 1,
                    borderRadius: sizes.dim1x,
                    overflow: 'hidden'
                },
                web: {
                    height: props.height,
                    width: props.width,
                    borderRadius: sizes.dim1x,
                    overflow: 'hidden'
                }
            })
        },
        cardContainer: {
            backgroundColor: colors.white,
            height: props.height * scale,
            overflow: 'hidden',
            ...Platform.select({
                ios: {
                    borderRadius: props.borderRadius || sizes.dim1x
                },
                web: {
                    width: props.width,
                    height: props.height,
                    borderWidth: 1,
                    borderRadius: sizes.dim1x,
                    borderColor: colors.paleBlue80
                }
            })
        }
    });
});

export default styles;
