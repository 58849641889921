import React from 'react';

import QRCode from 'react-qr-code';
import { Box, Stack } from '@mobily/stacks';

import { observer } from 'mobx-react';

import { EmText } from '@emjoy/shared/primitives';
import { L } from 'libs/i18n';

import { onboardingStore } from 'stores';

import { colors } from '@emjoy/shared/styles';
import { DesktopQrDownloadStyles } from './DesktopQrDownload.styles';

const DesktopQrDownload = observer(() => {
    return onboardingStore.signInWithEmailLink ? (
        <Box alignX={'center'} paddingY={4} style={DesktopQrDownloadStyles.qrWrapperStyle}>
            <Stack space={1} align={'center'}>
                <Box>
                    <QRCode
                        level={'H'}
                        value={onboardingStore.downloadLinks?.store || ''}
                        size={232}
                        fgColor={colors.secondary.c900}
                    />
                </Box>
                <Box>
                    <EmText type={'caption'} weight={'regular'}>
                        {L('webappQrScanWithPhone')}
                    </EmText>
                </Box>
            </Stack>
        </Box>
    ) : null;
});

export { DesktopQrDownload };
