import React, { Component } from 'react';
import { Box } from '@mobily/stacks';
import { EmImage } from '@emjoy/shared/primitives';
import { Pressable } from 'react-native';

class IconButton extends Component {
    render() {
        return (
            <Pressable
                accessible={this.props.accessible}
                accessibilityLabel={this.props.accessibilityLabel}
                accessibilityHint={this.props.accessibilityHint}
                accessibilityRole={'button'}
                disabled={this.props.disabled}
                onPressIn={this.props.onPressIn}
                onPressOut={this.props.onPressOut}
                onPress={this.props.onPress}
                style={{
                    width: this.props.containerSize || this.props.size + 20,
                    height: this.props.containerSize || this.props.size + 20
                }}
                testID={this.props.testID}
            >
                <Box
                    alignX={'center'}
                    alignY={'center'}
                    {...this.props.boxProps}
                    style={{
                        width: this.props.containerSize || this.props.size + 20,
                        height: this.props.containerSize || this.props.size + 20,
                        ...this.props.style
                    }}
                >
                    <EmImage
                        resizeMethod={'scale'}
                        source={this.props.icon}
                        width={this.props.size}
                        height={this.props.size}
                        tintColor={this.props.color}
                    />
                </Box>
            </Pressable>
        );
    }
}

export { IconButton };
