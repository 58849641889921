import React from 'react';

import { Box, Stack, useCurrentBreakpoint } from '@mobily/stacks';
import { isAndroid, isIOS } from 'react-device-detect';

import { L } from 'libs/i18n';

import { observer } from 'mobx-react';

import { onboardingStore } from 'stores';

import { EmImage, EmText } from '@emjoy/shared/primitives';
import { EmLink } from 'components/EmLink/EmLink';

import { downloadLinkAppstore, downloadLinkPlaystore, emjoywebUrl } from 'configs/conf';

import { colors } from '@emjoy/shared/styles';

import { images } from 'styles';
import { MobileDownloadStyle } from './MobileDownload.styles';

const MobileDownload = observer(({ logoColor = colors.primary.default, isNewUser }) => {
    const currentBreakpoint = useCurrentBreakpoint();
    const mobile = currentBreakpoint === 'mobile';

    const logoSize = {
        width: 96,
        height: 32
    };

    return (
        <Box flex={'fluid'}>
            {/* TODO simplify maybe the Header so we don't have to duplicate here? */}
            <Box paddingX={3} paddingTop={5} paddingBottom={2} alignY={'bottom'}>
                <EmLink target="_blank" rel="noindex, nofollow, noreferrer" href={emjoywebUrl}>
                    {mobile && <EmImage source={'logo_magenta'} style={logoSize} />}
                </EmLink>
            </Box>

            <Box padding={3}>
                <Stack space={3}>
                    <EmText type={'h1'}>
                        {isNewUser ? L('webappCaptionTitleDownload') : L('webappCaptionTitleDownloadReturningUser')}
                    </EmText>
                    <EmText type="normal" weight={'regular'} style={MobileDownloadStyle.downloadSecondaryText}>
                        {/* {isNewUser   //TODO once we add paywall
                            ? L('webappCaptionStep3Description')
                            : L('webappCaptionStep3DescriptionReturningUser')} */}
                        {isNewUser
                            ? L('webappCaptionStep5Description')
                            : L('webappCaptionStep5DescriptionReturningUser')}
                    </EmText>
                    <EmText type="normal" weight={'regular'} style={MobileDownloadStyle.downloadSecondaryText}>
                        {isNewUser ? L('webappDownloadApp') : null}
                    </EmText>
                    <EmLink
                        target="_blank"
                        rel="noindex, nofollow, noreferrer"
                        href={isAndroid ? onboardingStore.downloadLinks.android : onboardingStore.downloadLinks.ios}
                    >
                        {!isAndroid && (
                            <EmImage source={'webapp.apple_badge_black'} style={MobileDownloadStyle.downloadBadge} />
                        )}
                        {!isIOS && (
                            <EmImage source={'webapp.android_badge_black'} style={MobileDownloadStyle.downloadBadge} />
                        )}
                    </EmLink>
                </Stack>
            </Box>
        </Box>
    );
});

export { MobileDownload };
