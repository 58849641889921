/* eslint-disable */
import React, { Component } from 'react';
import { Keyboard, Text } from 'react-native';

import _ from 'lodash';
import { Box, Column, Columns, Stack } from '@mobily/stacks';
import { computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import { appStore, onboardingStore } from 'stores';
import { authErrors } from 'libs/conf';
import { callFirebaseFunction } from '@libs/callFirebaseFunction';
import { colors } from '@emjoy/shared/styles';
import { debug, debugConfigs } from '@emjoy/shared/configs';
import { emailLogIn, emailSignIn, facebookSignIn, googleSignIn, resetEmailPassword } from 'libs/signin';
import { EmButton, EmInput, EmText } from '@emjoy/shared/primitives';
import { L } from 'libs/i18n';
import { onboardingTexts } from 'libs/constants';
import { Page } from 'components/utils';
import { trackEvent } from 'src/libs/analytics';
import { updateReferral } from 'libs/referrals';
import { ppUrl, tosUrl } from 'configs/conf';

import styles from './pages.styles';
import { sizes } from 'styles';

const scale = sizes.screenWidth / 375;

const loginErrorAlert = ({ message, title, onOk }) => ({
    type: 'error',
    title: title || L('login_error'),
    okButton: L('ok'),
    message: message ? message : null,
    testID: 'loginErrorAlert',
    onOk
});

//TODO to functional
const LoginPage = observer(
    class LoginPage extends Component {
        email = '';
        password = '';
        promoCode = '';

        constructor(props) {
            super(props);

            makeObservable(this, {
                email: observable,
                password: observable,
                promoCode: observable,
                emptyData: computed,
                logingIn: observable,
                resettingPassword: observable
            });
        }

        get emptyData() {
            if (onboardingStore.loginType === 'signup') {
                return !(this.email && this.password);
            }
            if (onboardingStore.loginType === 'login') {
                return !(this.email && this.password);
            }
            return true;
        }

        logingIn = false;

        resettingPassword = false;

        onTosLink = () => {
            try {
                window.open(tosUrl, '_blank').focus();
            } catch (error) {}
        };

        onPpLink = () => {
            try {
                window.open(ppUrl, '_blank').focus();
            } catch (error) {}
        };

        onSwitchLogin = () => {
            onboardingStore.loginType = 'login';
        };

        onSwitchSignup = () => {
            onboardingStore.loginType = 'signup';
        };

        onLogin = async () => {
            if (this.emptyData) {
                this.props.navigation.navigate('Alert', {
                    type: 'error',
                    title: L('empty_fields'),
                    okButton: L('ok'),
                    message: L('complete_fields'),
                    testID: 'emtpyFieldsAlert'
                });
                return;
            }
            this.logingIn = 'email';

            if (this.promoCode.length > 0 && onboardingStore.loginType === 'signup') {
                const response = await updateReferral({ code: this.promoCode, onlyValidation: true, isNewUser: true });

                if (response.error) {
                    this.logingIn = false;

                    this.props.navigation.navigate('Alert', {
                        type: 'error',
                        title: L('promo_code_not_valid'),
                        okButton: L('ok'),
                        message: L('promo_code_check')
                    });
                    return;
                }

                if (response) {
                    debugConfigs.promoCode && debug.log('Code Data: ', { code: appStore.referralCode });
                    appStore.launchReferralCodes.code = this.promoCode;
                }
            }

            Keyboard.dismiss();

            let result = {};

            if (onboardingStore.loginType === 'signup') {
                result = await emailSignIn({ email: this.email, password: this.password });
            } else {
                result = await emailLogIn({ email: this.email, password: this.password });
            }

            if (result.user) {
                await appStore.getData();

                if (appStore.launchReferralCodes.code) {
                    await updateReferral({
                        code: appStore.launchReferralCodes.code,
                        isNewUser: result.additionalUserInfo?.isNewUser,
                        event: 'signup',
                        clearCode: true
                    });
                }

                this.props.onNext('signup', {
                    provider: 'email',
                    uid: result.user.uid,
                    email: result.user.email,
                    isNewUser: result.additionalUserInfo?.isNewUser
                });

                if (onboardingStore.loginType === 'signup') {
                    !debugConfigs.dontSendDynamicWelcomeEmail &&
                        (await callFirebaseFunction('sendDynamicWelcomeEmail', { sendWelcomeSignInLinkEmail: true }));
                }
            } else {
                let message = '',
                    title,
                    onOk;

                if (authErrors[result.error?.code]) {
                    message = authErrors[result.error?.code];
                }

                if (result.error?.code === 'auth/email-already-in-use') {
                    title = L('login_error_existing_account'); // TODO refactor this to be  moved outside in authErrors
                    onOk = () => {
                        onboardingStore.currentPosition = 4;
                        onboardingStore.isNewUser = false;
                    };
                }

                this.props.navigation.navigate('Alert', loginErrorAlert({ message, title, onOk }));
            }

            this.logingIn = false;
        };

        onLoginFacebook = async () => this.onLoginMethod('facebook', facebookSignIn);

        onLoginGoogle = async () => this.onLoginMethod('google', googleSignIn);

        onLoginMethod = async (provider, loginFunction) => {
            this.logingIn = provider;
            Keyboard.dismiss();
            const result = await loginFunction();

            if (result.user) {
                await appStore.getData();

                if (result.additionalUserInfo && result.additionalUserInfo?.isNewUser) {
                    !debugConfigs.dontSendDynamicWelcomeEmail &&
                        (await callFirebaseFunction('sendDynamicWelcomeEmail', { sendWelcomeSignInLinkEmail: true }));

                    this.props.onNext('signup', {
                        provider,
                        uid: result.user.uid,
                        isNewUser: result.additionalUserInfo?.isNewUser
                    });

                    trackEvent(onboardingStore.loginType, { provider, user_id: result.user.uid });
                } else {
                    let message = '',
                        title,
                        onOk;

                    title = L('login_error_existing_account'); // TODO refactor this to be  moved outside in authErrors
                    onOk = () => {
                        onboardingStore.isNewUser = false;
                        onboardingStore.currentPosition = 4; // TODO refactor this to be  moved outside in auth service?
                    };

                    this.props.navigation.navigate('Alert', loginErrorAlert({ message, title, onOk }));
                }
            } else {
                let message = '';
                if (authErrors[result.error?.code]) {
                    message = authErrors[result.error?.code];
                }

                if (result.error?.code !== 'auth/popup-closed-by-user') {
                    this.props.navigation.navigate('Alert', loginErrorAlert({ message }));
                }
            }
            this.logingIn = false;
        };

        onForgotPassword = () => {
            Keyboard.dismiss();
            onboardingStore.loginType = 'resetPassword';
            trackEvent('forgotPassword', {});
        };

        onResetPassword = async () => {
            let message = '';

            this.resettingPassword = true;

            Keyboard.dismiss();

            const result = await resetEmailPassword(this.email);

            if (result?.error) {
                if (authErrors[result.error?.code]) {
                    message = authErrors[result.error?.code];
                }
                this.props.navigation.navigate('Alert', {
                    type: 'error',
                    title: L('reset_error'),
                    okButton: L('ok'),
                    message: message ? message : null,
                    testID: 'resetEmailWrongFormatAlert'
                });

                this.resettingPassword = false;
            } else {
                this.props.navigation.navigate('Alert', {
                    type: 'info',
                    title: L('reset_password_success_title'),
                    okButton: L('ok'),
                    message: L('reset_password_success_message'),
                    testID: 'resetEmailSuccessAlert'
                });

                this.resettingPassword = false;
                this.onSwitchLogin();
            }
            trackEvent('resetPassword', {});
        };

        onCancelResetPassword = () => {
            Keyboard.dismiss();
            this.onSwitchLogin();
            this.resettingPassword = false;
            trackEvent('cancelResetPassword', {});
        };

        render() {
            const loginText = onboardingTexts.loginText[onboardingStore.loginType];

            return (
                <Page testID={'LoginPage'}>
                    <Box paddingTop={9} alignX={'center'} marginX={2}>
                        <Stack>
                            <EmInput
                                accessibilityLabel={L('email')}
                                accessibilityHint={L('enter_email')}
                                testID={'emailInput'}
                                placeholder={L('email')}
                                leftIcon={'email'}
                                editable={!(this.logingIn && this.xiaomifix)}
                                keyboardType="email-address"
                                autoCompleteType={'email'}
                                value={this.email}
                                onChange={value => (this.email = value)}
                            />
                            <EmInput
                                autoCompleteType={'password'}
                                accessibilityLabel={L('password')}
                                accessibilityHint={
                                    onboardingStore.loginType === 'signup' ? L('choose_password') : L('enter_password')
                                }
                                testID={'passwordInput'}
                                placeholder={L('password')}
                                leftIcon={'password'}
                                editable={!this.logingIn}
                                isPassword
                                value={this.password}
                                onChange={value => (this.password = value)}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                            />
                            {/* TODO We disable for now the promocode till we manage to  unify payments */}
                            {/* <EmInput
                                onlyUppercase
                                accessibilityLabel={L('promo_code')}
                                accessibilityHint={L('promo_code_enter')}
                                testID={'promoCodeInput'}
                                placeholder={L('promo_code_enter')}
                                editable={!appStore.launchReferralCodes.code}
                                value={this.promoCode || appStore.launchReferralCodes.code}
                                onChange={(value) => (this.promoCode = value)}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                            /> */}
                            <EmButton
                                accessibilityLabel={L('sign_up')}
                                accessibilityHint={L('setup_account')}
                                testID={'signUpBtn'}
                                isBusy={this.logingIn === 'email'}
                                disabled={this.logingIn}
                                onPress={this.onLogin}
                                title={L('sign_up')}
                                small={scale >= 1 ? false : true}
                                paddingY={1.5}
                            />

                            <Columns space={1} marginTop={4}>
                                {/* <Column>
                                    <EmButton
                                        accessibilityLabel={`Facebook ${loginText}`}
                                        accessibilityHint={`${L('use_account_info_to')} ${loginText}`}
                                        testID={'facebookBtn'}
                                        iconLeft={'facebook'}
                                        iconSize={sizes.dim3x}
                                        isBusy={this.logingIn === 'facebook'}
                                        disabled={this.logingIn}
                                        onPress={this.onLoginFacebook}
                                        minMargin
                                        style={styles.ssoFacebook}
                                        small={scale >= 1 ? false : true}
                                        boxProps={{ flex: 'fluid', alignX: 'center' }}
                                    />
                                </Column> */}
                                <Column>
                                    <EmButton
                                        accessibilityLabel={`Google ${loginText}`}
                                        accessibilityHint={`${L('use_account_info_to')} ${loginText}`}
                                        testID={'googleBtn'}
                                        iconLeft={'google'}
                                        iconSize={sizes.dim3x}
                                        isBusy={this.logingIn === 'google'}
                                        disabled={this.logingIn}
                                        onPress={this.onLoginGoogle}
                                        minMargin
                                        style={styles.ssoGoogle}
                                        small={scale >= 1 ? false : true}
                                        boxProps={{ flex: 'fluid', alignX: 'center' }}
                                    />
                                </Column>
                            </Columns>
                            {/* EmText doesn't allow links betweeen text, ftm */}
                            {onboardingStore.loginType === 'signup' && (
                                <Box>
                                    <EmText
                                        color={colors.secondary.c900}
                                        weight={'regular'}
                                        textAlign={'center'}
                                        type={'caption'}
                                        boxProps={{ marginTop: scale >= 1 ? 2 : 0 }}
                                        testID={'termsPolicyText'}
                                    >
                                        {`${L('terms_continue')}\n`}
                                        <Text onPress={this.onTosLink} style={{ color: colors.primary.default }}>
                                            {L('terms_and_conditions')}
                                        </Text>
                                        {` ${L('and')} `}
                                        <Text onPress={this.onPpLink} style={{ color: colors.primary.default }}>
                                            {L('privacy_policy')}
                                        </Text>
                                    </EmText>
                                </Box>
                            )}
                        </Stack>
                    </Box>
                </Page>
            );
        }
    }
);

export { LoginPage };
