import { StyleSheet } from 'react-native';

import { colors } from '@emjoy/shared/styles';
import { sizes, texts } from 'styles';

const style = StyleSheet.create({
    goalsWrapper: {
        justifyContent: 'space-around',
        flexDirection: 'column',
        padding: sizes.dim2x,
        paddingTop: 0
    },
    goalItemRowWrapper: {
        borderRadius: sizes.dim2x,
        width: sizes.screenWidth - 2 * sizes.dim2x,
        height: 80,
        backgroundColor: 'white'
        //border: '1px solid #ccc'
    },
    goalItemRowIcon: {
        width: sizes.dim3x,
        height: sizes.dim3x,
        tintColor: colors.secondary.default
    },
    goalItemRowText: { ...texts.sessionRowTitle, flex: 1, color: colors.secondary.default },

    goalItemContainer: {
        // width: sizes.screenWidth - 2 * sizes.dim2x,
        // height: 88,
        borderRadius: sizes.dim2x,
        shadowColor: '#fff',
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0,
        shadowRadius: 0
    },

    goalItemContainerActive: {
        shadowColor: colors.secondary.c400,
        shadowOffset: {
            width: 0,
            height: 5
        },
        shadowOpacity: 0.15,
        shadowRadius: 20
    },
    goalItemWrapper: {
        flex: 1,
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    goalItemIcon: {
        width: sizes.dim3x,
        height: sizes.dim3x,
        tintColor: colors.paleBlue
    },
    goalItemImage: {
        width: 64,
        height: 64,
        alignSelf: 'flex-end'
    },
    goalItemIconActive: { tintColor: colors.primary.default },
    goalItemText: { ...texts.sessionRowTitle, marginHorizontal: null, textAlign: 'center' },
    goalItemTextActive: { color: colors.primary.default },
    greyImage: { filter: 'grayscale(100%)' }
});

export default style;
