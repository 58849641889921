import React from 'react';

import './index.css';

import ReactDOM from 'react-dom';

import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver; //Safari ios 12 needs the polyfill

// import reportWebVitals from './reportWebVitals';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import { StacksProvider } from '@mobily/stacks';

import { App } from './App';

import { setup, trackEvent } from '@libs/analytics';

setup();

ReactDOM.render(
    <React.StrictMode>
        <SafeAreaProvider
            initialMetrics={{
                frame: { x: 0, y: 0, width: 0, height: 0 },
                insets: { top: 0, left: 0, right: 0, bottom: 0 }
            }}
        >
            <StacksProvider
                spacing={8}
                breakpoints={[
                    ['mobile', 0],
                    ['tablet', 768],
                    ['desktop', 992]
                ]}
            >
                <App />
            </StacksProvider>
        </SafeAreaProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
