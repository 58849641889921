import { colors } from '@emjoy/shared/styles';

const textFonts = {
    primary: {
        fontFamily: 'Quicksand-Bold'
    },
    secondary: {
        fontFamily: 'Quicksand-Medium'
    },
    system: {
        fontFamily: 'System'
    }
};

// const scale = sizes.screenWidth / sizes.BASE_SCREEN_WIDTH > 1 ? sizes.screenWidth / sizes.BASE_SCREEN_WIDTH : 1;
const scale = 1;

/*
    TODO scale is not working, small devices is always less than 1, so scale afor font sizes always is 1
    console.log({
    screenWidth: sizes.screenWidth,
    baseScreenWith: sizes.BASE_SCREEN_WIDTH,
    ratio: sizes.screenWidth / sizes.BASE_SCREEN_WIDTH
}); */

const fontSizes = {
    size1: 12 * scale,
    size2: 14 * scale,
    size3: 16 * scale,
    size4: 18 * scale,
    size5: 20 * scale,
    size6: 24 * scale,
    size7: 30 * scale,
    size8: 34 * scale,
    sizeExtra5: 40 * scale,
    sizeExtra6: 48 * scale,
    sizeExtra7: 56 * scale,
    sizeExtra8: 64 * scale,
    sizeExtra6Special: 46 * scale
};

const lineHeights = {
    lineHeight1: 16,
    lineHeight2: 20,
    lineHeight3: 22,
    lineHeight4: 26,
    lineHeight5: 32,
    lineHeight6: 40,
    sizeExtra5: 40 * scale,
    sizeExtra6: 48 * scale,
    sizeExtra7: 56 * scale,
    sizeExtra8: 64 * scale
};

const textStyle = {
    displayBold: {
        ...textFonts.primary,
        fontSize: fontSizes.size8,
        lineHeight: lineHeights.lineHeight6
    },
    headlineBold: {
        ...textFonts.primary,
        fontSize: fontSizes.size6,
        lineHeight: lineHeights.lineHeight5
    },
    titleBold: {
        ...textFonts.primary,
        fontSize: fontSizes.size5,
        lineHeight: lineHeights.lineHeight4
    },
    descriptionLargeMedium: {
        ...textFonts.secondary,
        fontSize: fontSizes.size5,
        lineHeight: lineHeights.lineHeight4
    },
    subtitleBold: {
        ...textFonts.primary,
        fontSize: fontSizes.size4
    },
    contentTitleBold: {
        ...textFonts.primary,
        fontSize: fontSizes.size3,
        lineHeight: lineHeights.lineHeight3
    },
    contentTitleMedium: {
        ...textFonts.secondary
        // fontSize: fontSizes.size3,
        // lineHeight: lineHeights.lineHeight3
    },
    descriptionSmallBold: {
        ...textFonts.primary,
        fontSize: fontSizes.size2,
        lineHeight: lineHeights.lineHeight2
    },
    descriptionSmallMedium: {
        ...textFonts.secondary,
        fontSize: fontSizes.size2,
        lineHeight: lineHeights.lineHeight2
    },
    number: {
        ...textFonts.system,
        fontSize: fontSizes.size2,
        lineHeight: lineHeights.lineHeight2
    },
    disclaimerMedium: {
        ...textFonts.secondary,
        fontSize: fontSizes.size1,
        lineHeight: lineHeights.lineHeight1
    }
};

const textShadow = {
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 3,
    textShadowColor: colors.shadowColor
};

export { fontSizes, textShadow, textFonts, textStyle, lineHeights };
