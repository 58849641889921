const htmlToString = html => {
    return html
        ? html
              .replace('<p><br></p>', ' ')
              .replace('<p></p>', ' ')
              .replace(/<[^>]+>/g, '')
        : '';
};

const htmlRemoveEmptySpaces = html => {
    return html.replace('<p><br></p>', ' ').replace('<p></p>', ' ');
};

export { htmlToString, htmlRemoveEmptySpaces };
