import React from 'react';

import _ from 'lodash';
import { Box, useCurrentBreakpoint } from '@mobily/stacks';
import { observer } from 'mobx-react';
import { ScrollView } from 'react-native';

import { captionSteps, DesktopQrDownload, MobileDownload, Wrapper } from 'components/onboarding';
import { GoalsPage } from 'screens/onboarding/GoalsPage';
import { LoginPage } from 'screens/onboarding/LoginPage';
// import { PaywallPage } from 'components/pages/PaywallPage/PaywallPage'; // we kill the paywall for now
// import { StripeScreen } from 'screens/onboarding/StripeScreen'; // we kill the paywall for now

import { SvgHeader } from 'components/utils';

import { onboardingStore, userStore } from 'stores';
import { trackEvent } from 'libs/analytics';

import styles from '@screens/onboarding/pages.styles';

const OnboardingScreen = observer(({ navigation }) => {
    const currentBreakpoint = useCurrentBreakpoint();
    const mobile = currentBreakpoint === 'mobile';

    const title = captionSteps[onboardingStore.currentPosition]?.title;

    const onNext = async (page, data) => {
        if (page === 'login') {
            onboardingStore.currentPosition = 2;
        }

        if (page === 'signup') {
            onboardingStore.currentPosition = 1;
            if (!data.isNewUser) {
                onboardingStore.isNewUser = false;
            }
            trackEvent('signup', { provider: data.provider, uid: data.uid, email: data.email });
            trackEvent('webAppSignup', { provider: data.provider, uid: data.uid, email: data.email });
        }

        if (page === 'goals') {
            userStore.initialSelectedGoals = data.selectedGoals;
            userStore.setUserSelectedGoals('login');

            onboardingStore.currentPosition = 2;
        }
        if (page === 'paywall') {
            onboardingStore.currentPosition = data.skipPayment ? 4 : 3;
        }

        if (page === 'stripe') {
            onboardingStore.currentPosition = 4;
        }
    };

    return (
        <ScrollView contentContainerStyle={[styles.sv, !mobile ? styles.svMobile : null]}>
            <Wrapper
                currentPosition={onboardingStore.currentPosition}
                isNewUser={onboardingStore.isNewUser}
                noHeader={mobile && onboardingStore.currentPosition === 2} // we kill the paywall for now was 4
                noApp={onboardingStore.currentPosition === 2} // we kill the paywall for now was 4
            >
                <Box
                    flex={'fluid'}
                    alignSelf={'stretch'}
                    alignX={'center'}
                    marginTop={onboardingStore.hasHeader ? 13 : 0}
                >
                    {onboardingStore.currentPosition === 0 && <LoginPage navigation={navigation} onNext={onNext} />}

                    {onboardingStore.currentPosition === 1 && <GoalsPage navigation={navigation} onNext={onNext} />}

                    {/* {onboardingStore.currentPosition === 2 && ( // we kill the paywall for now
                        <PaywallPage
                            navigation={navigation}
                            onNext={onNext}
                            mobile={mobile}
                            onClose={() => {
                                onNext('paywall', { skipPayment: true });
                            }}
                        />
                    )} */}
                    {/* {onboardingStore.currentPosition === 3 && <StripeScreen navigation={navigation} onNext={onNext} // we kill the paywall for now />} */}

                    {onboardingStore.currentPosition === 2 && // we kill the paywall for now was 4
                        (mobile ? (
                            <MobileDownload navigation={navigation} isNewUser={onboardingStore.isNewUser} />
                        ) : (
                            <DesktopQrDownload />
                        ))}
                </Box>
                {onboardingStore.hasHeader && (
                    <SvgHeader style={styles.svgHeader} title={mobile ? title : null} isMobile={mobile} />
                )}
            </Wrapper>
        </ScrollView>
    );
});

export { OnboardingScreen };
