import { Dimensions, Platform, StatusBar } from 'react-native';

const { height, width } = Dimensions.get('window');
const isIOS = Platform.OS === 'ios';
const isWeb = Platform.OS === 'web';

let statusBarHeight;

if (isIOS) {
    statusBarHeight = height >= 812 ? 44 : 20;
} else {
    statusBarHeight = StatusBar.currentHeight;
}

let toolbarHeight;

if (isIOS) {
    toolbarHeight = height >= 812 ? 56 : 44;
} else {
    toolbarHeight = 56;
}
const BASE_SCREEN_WIDTH = 375;

const sizes = {
    dim05x: 4,
    dim1x: 8,
    dim15x: 12,
    dim2x: 16,
    dim25x: 20,
    dim3x: 24,
    dim4x: 32,
    dim5x: 40,
    dim6x: 48,
    dim7x: 56,
    dim8x: 64,
    dim9x: 72,
    dim10x: 80,
    screenWidth: Math.ceil(width),
    screenHeight: Math.ceil(height),
    statusBarHeight,
    toolbarHeight,
    BASE_SCREEN_WIDTH: BASE_SCREEN_WIDTH,
    HEADER_HEIGHT: parseInt((260 * width) / BASE_SCREEN_WIDTH, 10)
};

let scale = 1;

if (!isWeb) {
    scale = sizes.screenWidth / BASE_SCREEN_WIDTH > 1 ? sizes.screenWidth / BASE_SCREEN_WIDTH : 1;
}

// quick hotfix for android with bigscreen 1080px / 360px that scales to small and screen is big
// https://emjoy.atlassian.net/browse/EMJ-43?atlOrigin=eyJpIjoiNjZhNTU0MGE5ZGE5NGM2NWIzZGZjMTQ4YmI0NmJlZjEiLCJwIjoiaiJ9
const isSmallSizeFix = Platform.OS === 'ios' ? sizes.screenWidth / 375 < 1 : !(sizes.screenWidth / 360 >= 1);

export { sizes, isSmallSizeFix, scale };
