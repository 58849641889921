const firebaseConfig = {
    apiKey: 'AIzaSyB9r80KiBBUT14UnJl6PNMFtWdMo2pJDGM',
    authDomain: 'emjoyproduction.firebaseapp.com',
    databaseURL: 'https://emjoyproduction.firebaseio.com',
    projectId: 'emjoyproduction',
    storageBucket: 'emjoyproduction.appspot.com',
    messagingSenderId: '736801916223',
    appId: '1:736801916223:web:eba6b8b7535e45a73eff8a',
    measurementId: 'G-SD325G7JY2'
};

export { firebaseConfig };
