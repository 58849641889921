import facebook from 'assets/images/social/facebook@2x.png';
import google from 'assets/images/social/google@2x.png';

import alertIcon from 'assets/images/alert@2x.png';
import android_badge_black from 'assets/images/onboarding/android_badge_black.png';
import android_badge_white from 'assets/images/onboarding/android_badge_white.png';
import answerIsResultIcon from 'assets/images/answerIsResultIcon.png';
import answerIsRightIcon from 'assets/images/answerIsRightIcon.png';
import answerIsWrongIcon from 'assets/images/answerIsWrongIcon.png';
import apple from 'assets/images/social/apple@2x.png';
import apple_badge_black from 'assets/images/onboarding/apple_badge_black.png';
import apple_badge_white from 'assets/images/onboarding/apple_badge_white.png';
import audioButton from 'assets/images/audio.png';
import aym from 'assets/images/goals/aym.png';
import aym_bw from 'assets/images/goals/aym_bw.png';
import calendar from 'assets/images/paywallFeatures/calendar.png';
import cc from 'assets/images/goals/cc.png';
import cc_bw from 'assets/images/goals/cc_bw.png';
import checkBoxChecked from 'assets/images/CheckBoxChecked.png';
import checkBoxEmpty from 'assets/images/CheckBoxEmpty.png';
import checkedIcon from 'assets/images/checked.png';
import checkSign from 'assets/images/checkSign.png';
import clock from 'assets/images/clock.png';
import closeButton from 'assets/images/close@2x.png';
import closeNoCircleButton from 'assets/images/closeNoCircle@2x.png';
import congrats from 'assets/images/congrats.png';
import contents from 'assets/images/paywallFeatures/contents.png';
import continueArrow from 'assets/images/continueArrow.png';
import defaultArtwork from 'assets/images/defaultArtwork.png';
import desireBackground from 'assets/images/desireBackground.png';
import desireBackgroundTransparent from 'assets/images/desireBackground-trans.png';
import downloadButton from 'assets/images/download.png';
import downloadPageDevices from 'assets/images/onboarding/devices.png';
import dy from 'assets/images/goals/dy.png';
import dy_bw from 'assets/images/goals/dy_bw.png';
import email from 'assets/images/email@2x.png';
import emjoyIcon from 'assets/images/emjoy@2x.png';
import errorIcon from 'assets/images/error@2x.png';
import ert from 'assets/images/goals/ert.png';
import ert_bw from 'assets/images/goals/ert_bw.png';
import fbp from 'assets/images/goals/fbp.png';
import fbp_bw from 'assets/images/goals/fbp_bw.png';
import flatBackButton from 'assets/images/flatBack@2x.png';
import flatForwardButton from 'assets/images/flatForward.png';
import footerWhiteWave from 'assets/images/footerWhiteWave.png';
import gift from 'assets/images/gift.png';
import guidedSessions from 'assets/images/paywallFeatures/guidedSessions.png';
import habitArouseBanner from 'assets/images/habitArouseBanner.png';
import habitArouseDone from 'assets/images/habitDone.png';
import habitArouseHeader from 'assets/images/habitArouseHeader.png';
import habitArouseIllustration from 'assets/images/habitArouseIllustration.png';
import habitArouseMindCard from 'assets/images/HabitArouseCard.png';
import habitBodyPositiveBanner from 'assets/images/habitBodyPositiveBanner.png';
import habitBodyPositiveCard from 'assets/images/HabitBodyCard.png';
import habitBodyPositiveHeader from 'assets/images/habitBodyPositiveHeader.png';
import habitBodyPositiveIllustration from 'assets/images/habitBodyPositiveIllustration.png';
import habitChallengeStep from 'assets/images/habits/Step-Disabled.png';
import habitChallengeStepActive from 'assets/images/habits/Step-Active.png';
import habitChallengeStepLocked from 'assets/images/habits/Step-Locked.png';
import habitDailyPleasuresCard from 'assets/images/habitDailyPleasuresCard.png';
import habitDailyPleasuresHeader from 'assets/images/habitDailyPleasuresHeader.png';
import habitDone from 'assets/images/habitDone.png';
import habitSelfTalkCard from 'assets/images/habitSelfTalkCard.png';
import habitSelfTalkHeader from 'assets/images/habitSelfTalkHeader.png';
import il from 'assets/images/goals/il.png';
import il_bw from 'assets/images/goals/il_bw.png';
import informationIcon from 'assets/images/information@2x.png';
import instagramIcon from 'assets/images/instagramIcon.png';
import lnt from 'assets/images/goals/lnt.png';
import lnt_bw from 'assets/images/goals/lnt_bw.png';
import lockedWhite from 'assets/images/LockedWhite.png';
import lockGrey from 'assets/images/lockGrey.png';
import logo_magenta from 'assets/images/logo_magenta@2x.png';
import navigatorDiscover from 'assets/images/Discover.png';
import navigatorJourney from 'assets/images/Journey.png';
import navigatorStories from 'assets/images/StoriesBook.png';
import navigatorToday from 'assets/images/Today.png';
import newLabel from 'assets/images/New.png';
import newSessionBottom from 'assets/images/NewSession-Bottom.png';
import newSessions from 'assets/images/NewSessions.png';
import offerHeader from 'assets/images/offerHeader.jpg';
import offerRibbon from 'assets/images/OfferRibbon.png';
import offline from 'assets/images/offline.png';
import onboardingCheck from 'assets/images/onboarding/onboardingCheck.png';
import p10 from 'assets/images/progress/p10.jpg';
import p13 from 'assets/images/progress/p13.jpg';
import p15 from 'assets/images/progress/p15.jpg';
import p2 from 'assets/images/progress/p2.jpg';
import p5 from 'assets/images/progress/p5.jpg';
import p7 from 'assets/images/progress/p7.jpg';
import p8 from 'assets/images/progress/p8.jpg';
import p9 from 'assets/images/progress/p9.jpg';
import password from 'assets/images/password@2x.png';
import passwordHidden from 'assets/images/passwordHidden@2x.png';
import passwordVisible from 'assets/images/passwordVisible@2x.png';
import pauseButton from 'assets/images/pause.png';
import paywallBestValue from 'assets/images/labelBestValue@2x.png';
import paywallCancelIcon from 'assets/images/paywall_cancel.png';
import paywallCardIcon from 'assets/images/paywall_card.png';
import paywallGiftIcon from 'assets/images/paywall_gift.png';
import paywallKeyIcon from 'assets/images/paywall_key.png';
import playButton from 'assets/images/play.png';
import playGrey from 'assets/images/playGrey.png';
import playSmall from 'assets/images/playSmall.png';
import playWhite from 'assets/images/PlayWhite.png';
import plusButton from 'assets/images/plus.png';
import privateIcon from 'assets/images/Private.png';
import privateIconWhite from 'assets/images/PrivateWhite.png';
import publicIcon from 'assets/images/Public.png';
import publicIconWhite from 'assets/images/PublicWhite.png';
import pushPermissionsPopup from 'assets/images/pushPermissionsPopup.png';
import readButton from 'assets/images/read.png';
import readSession from 'assets/images/readSessionButtonWhite.png';
import referralBanner from 'assets/images/referralInvitesBanner.png';
import referralCollectionImage from 'assets/images/referralCollectionImage.png';
import referralInvite from 'assets/images/referralInviteIllustration.png';
import referralPaying from 'assets/images/ReferralPaying.png';
import referralPopup from 'assets/images/ReferralPopup.png';
import reminderArouse from 'assets/images/reminderArouse.png';
import reminderBody from 'assets/images/reminderBody.png';
import reminderDailyPleasure from 'assets/images/reminderDailyPleasure.png';
import reminderGlobal from 'assets/images/reminderGlobal.png';
import reminderSelfTalk from 'assets/images/reminderSelfTalk.png';
import settingsButton from 'assets/images/settings.png';
import shareAndroid from 'assets/images/shareAndroid.png';
import shareApple from 'assets/images/shareApple.png';
import sitBackground from 'assets/images/sitBackground.png';
import sitBackgroundTransparent from 'assets/images/sitBackground-trans.png';
import skipBackButton from 'assets/images/backward15.png';
import skipForwardButton from 'assets/images/forward15.png';
import spdi from 'assets/images/goals/spdi.png';
import spdi_bw from 'assets/images/goals/spdi_bw.png';
import spicyness from 'assets/images/spicyness.png';
import spicynessWhite from 'assets/images/spicynessWhite.png';
import starEmpty from 'assets/images/feedback/starEmpty.png';
import starFilled from 'assets/images/feedback/starFilled.png';
import starFilledNoborder from 'assets/images/feedback/starFilledNoborder.png';
import starTranslucent from 'assets/images/feedback/starTranslucent.png';
import stories from 'assets/images/paywallFeatures/stories.png';
import successScreenBadge from 'assets/images/onboarding/successScreenBadge@2x.png';
import surveyBottomDecorator from 'assets/images/surveyBottomDecorator.png';
import surveyTopDecorator from 'assets/images/surveyTopDecorator.png';
import tabHomeIcon from 'assets/images/tabHome.png';
import tabProfileIcon from 'assets/images/tabProfile.png';
import tabSessionsIcon from 'assets/images/tabSessions.png';
import thumbImage from 'assets/images/thumbImage.png';
import thumbSliderBg from 'assets/images/thumbSliderBg.png';
import timeButton from 'assets/images/time.png';
import todayJourneyThumbnail from 'assets/images/JourneyToday.png';
import under_construction from 'assets/images/under_construction.png';
import user from 'assets/images/user.png';
import webOnboaringFooterWaveDesktop from 'assets/images/onboarding/webOnboaringFooterWaveDesktop.png';
import webOnboaringFooterWaveMobile from 'assets/images/onboarding/webOnboaringFooterWaveMobile.png';

const images = {
    footerWhiteWave,
    defaultArtwork,
    logo_magenta,
    flatBackButton,
    flatForwardButton,
    checkedIcon,
    closeButton,
    closeNoCircleButton,
    congrats,
    gift,
    playButton,
    playSmall,
    pauseButton,
    skipBackButton,
    skipForwardButton,
    successScreenBadge,
    downloadButton,
    readButton,
    tabHomeIcon,
    tabSessionsIcon,
    tabProfileIcon,
    user,
    newLabel,
    newSessions,
    publicIcon,
    privateIcon,
    publicIconWhite,
    privateIconWhite,
    sitBackground,
    sitBackgroundTransparent,
    desireBackground,
    desireBackgroundTransparent,
    checkBoxEmpty,
    checkBoxChecked,
    informationIcon,
    alertIcon,
    emjoyIcon,
    errorIcon,
    offerRibbon,
    continueArrow,
    spicyness,
    spicynessWhite,
    habitBodyPositiveBanner,
    habitBodyPositiveIllustration,
    habitBodyPositiveHeader,
    habitDone,
    habitArouseBanner,
    habitArouseIllustration,
    habitArouseHeader,
    habitArouseDone,
    readSession,
    clock,
    referralPopup,
    reminderArouse,
    reminderBody,
    reminderGlobal,
    newSessionBottom,
    lockedWhite,
    playWhite,
    playGrey,
    lockGrey,
    referralPaying,
    habitBodyPositiveCard,
    habitArouseMindCard,
    referralInvite,
    referralBanner,
    instagramIcon,
    thumbImage,
    thumbSliderBg,
    shareApple,
    shareAndroid,
    checkSign,

    answerIsResultIcon,
    answerIsRightIcon,
    answerIsWrongIcon,

    surveyBottomDecorator,
    surveyTopDecorator,

    habitSelfTalkCard,
    habitSelfTalkHeader,
    habitDailyPleasuresCard,
    habitDailyPleasuresHeader,
    reminderDailyPleasure,
    reminderSelfTalk,

    habitChallengeStepActive,
    habitChallengeStepLocked,
    habitChallengeStep,

    navigatorToday,
    navigatorDiscover,
    navigatorStories,
    navigatorJourney,

    email,

    password,
    passwordVisible,
    passwordHidden,

    facebook,

    google,

    apple,
    under_construction,
    settingsButton,
    timeButton,
    audioButton,

    plusButton,
    p2,
    p5,
    p7,
    p8,
    p9,
    p10,
    p13,
    p15,
    paywallGiftIcon,
    paywallKeyIcon,
    paywallCancelIcon,
    paywallCardIcon,
    paywallBestValue,
    feedback: {
        starEmpty,
        starFilledNoborder,
        starFilled,
        starTranslucent
    },
    goals: {
        fbp,
        fbp_bw,

        dy,
        dy_bw,

        il,
        il_bw,

        spdi,
        spdi_bw,

        aym,
        aym_bw,

        cc,
        cc_bw,

        lnt,
        lnt_bw,

        ert,
        ert_bw
    },
    paywallFeatures: {
        calendar,
        contents,
        guidedSessions,
        stories
    },
    offerHeader,
    offline,

    pushPermissionsPopup,
    referralCollectionImage,

    todayJourneyThumbnail,
    webapp: {
        downloadPageDevices,
        android_badge_white,
        apple_badge_white,
        android_badge_black,
        apple_badge_black,
        webOnboaringFooterWaveDesktop,
        webOnboaringFooterWaveMobile,
        onboardingCheck
    }
};

const svgPaths = {
    defaultSvgFooterPath: {
        d: 'M375 0c-48 85-66 15-154 65C130 117 76 9 0 51v46h375V0Z'
    },
    testResult: {
        // d: 'M375 32v65H0v-19c41-48 96 18 204-54 56-37 117-29 171 8z' //TODO: Delete when sure it works fine below one
        d: 'M375 0l.00 72c-54-22-115-28-171-5-108 43-163 4-204 32L0 0z',
        fill: 'url(#gradient)' // TODO this might need an unique id
    },
    headerCurveOne: {
        d: 'M375 0l.00 {curveStarts}c-54-22-115-28-171-5-108 43-163 4-204 32L0 0z',
        curveStartDifference: 30
    },
    headerCurveTwo: {
        d: 'M375 {curveStarts}c-58 74-54 1-148 36-97 37-92-1-145-38-38-27-53-10-82-16 v0V0h375z',
        curveStartDifference: 85
    }
};

export { images, svgPaths };
