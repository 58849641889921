/* eslint-disable */
import { makeAutoObservable } from 'mobx';
class WebViewStore {
    constructor() {
        makeAutoObservable(this);
    }

    userStore = {
        hasValidSubscription: window.hasValidSubscription,
        hasReferralCollection: window.hasReferralCollection,
        surveyData: window.surveyData
    };

    appNavigate = (navigate, navigateProps) => {
        this.sendObjectToApp({ navigate, navigateProps });
    };

    //Remember only send one argument
    consoleLogInApp = object => {
        this.sendObjectToApp({ log: object });
    };

    trackEventInApp = object => {
        this.sendObjectToApp({ trackEvent: object });
    };

    sendObjectToApp = object => {
        window.ReactNativeWebView?.postMessage(JSON.stringify(object));
    };
}

const webViewStore = new WebViewStore();

export { webViewStore };
