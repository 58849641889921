import React, { forwardRef, useState } from 'react';
import { Platform, Pressable, TextInput } from 'react-native';

import _ from 'lodash';

import { Box } from '@mobily/stacks';
import { getBrand } from 'react-native-device-info';

import { EmIcon } from '../EmIcon/EmIcon';

import styles from './EmInput.styles';
import { colors, isSmallSizeFix } from '@emjoy/shared/styles';

// Workaround 2.4.0 (nov 2020) for xiaomi crashes, check in the future if the issue is still there.
const brandsNeedingWorkaround = [
    'redmi',
    'xiaomi',
    'poco',
    'pocophone',
    'mi',
    'mix',
    'm2002j9g',
    'm2004j19c',
    'm2007j20cg'
];

const needsXiaomiWorkaround =
    Platform.OS !== 'ios' ? brandsNeedingWorkaround.includes(getBrand().toLowerCase()) : false;

//

const EmInput = forwardRef((props, ref) => {
    const [active, setActive] = useState(false);

    const {
        autoCapitalize,
        autoCompleteType, //legacy
        autoComplete,
        accessibilityLabel,
        accessibilityHint,
        testID,
        keyboardType,
        returnKeyType,
        placeholder,
        isPassword,
        boxProps,
        leftIcon,
        leftIconTintColor,
        leftIconMargin,
        rightIcon,
        rightIconTintColor,
        rightIconMargin,
        onRightIconPress,
        title,
        editable = true,
        enablesReturnKeyAutomatically = false,
        style,
        textInputWraperStyle,
        placeholderTextColor,
        selectionColor,
        multiline,
        autoFocus,
        maxLength,
        thinFontWeight
    } = props;

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordImage = passwordVisible ? 'hidePassword' : 'visiblePassword';

    const onChange = input => {
        const parsedValue = props.onlyUppercase ? _.toUpper(input) : input;
        props.onChange?.(parsedValue);
    };

    const onToggleVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const onFocus = () => {
        setActive(true);
        props.onFocus?.();
    };

    const onBlur = () => {
        setActive(false);
        props.onBlur?.();
    };

    return (
        <Box
            direction={'row'}
            marginY={isSmallSizeFix ? 0.5 : 2}
            paddingX={1}
            alignY={'center'}
            style={[
                styles.textInputWraper,
                active ? textInputWraperStyle?.wrapperActive : textInputWraperStyle?.wrapperNotActive
            ]}
            {...boxProps}
        >
            {leftIcon && (
                <EmIcon
                    size={isSmallSizeFix ? 20 : 24}
                    boxProps={{ marginLeft: leftIconMargin || 3 }}
                    name={leftIcon}
                    color={
                        active
                            ? leftIconTintColor?.iconActive || 'primary.default'
                            : leftIconTintColor?.iconNotActive || 'secondary.c100'
                    }
                />
            )}
            <TextInput
                ref={ref}
                accessibilityLabel={accessibilityLabel}
                accessibilityHint={accessibilityHint}
                accessibilityRole="text"
                testID={testID}
                style={
                    !props.value || thinFontWeight
                        ? [styles.textInput, style, styles.textInputEmpty]
                        : [styles.textInput, style]
                }
                editable={editable}
                autoCorrect={false}
                autoComplete={autoComplete ?? autoCompleteType ?? 'off'}
                keyboardType={keyboardType ?? 'default'}
                autoCapitalize={autoCapitalize || (props.onlyUppercase ? 'characters' : 'none')}
                placeholder={placeholder}
                placeholderTextColor={placeholderTextColor ?? colors.neutral.c800}
                secureTextEntry={isPassword && !passwordVisible}
                onFocus={onFocus}
                onBlur={onBlur}
                onChangeText={onChange}
                value={props.value}
                caretHidden={needsXiaomiWorkaround}
                onSubmitEditing={props.onSubmitEditing}
                enablesReturnKeyAutomatically={enablesReturnKeyAutomatically}
                returnKeyType={returnKeyType ?? null}
                selectionColor={selectionColor}
                multiline={multiline}
                autoFocus={autoFocus}
                maxLength={maxLength}
            />
            {(rightIcon || isPassword) && (
                <Pressable
                    onPress={isPassword ? onToggleVisibility : onRightIconPress}
                    disabled={!editable}
                    accessibilityLabel={title}
                    accessibilityHint={title}
                    accessibilityRole="button"
                >
                    <EmIcon
                        size={isSmallSizeFix ? 20 : 24}
                        boxProps={{ marginRight: rightIconMargin || 3 }}
                        name={isPassword ? togglePasswordImage : rightIcon}
                        color={
                            active
                                ? isPassword
                                    ? 'secondary.c100'
                                    : rightIconTintColor?.iconActive || 'primary.default'
                                : rightIconTintColor?.iconNotActive || 'secondary.c100'
                        }
                    />
                </Pressable>
            )}
        </Box>
    );
});

export { EmInput };
