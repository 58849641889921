import _ from 'lodash';
import axios from 'axios';
import { observable, when } from 'mobx';

import { downloadLinkDesktop, getUserCountryUrl } from 'configs/conf';
import { getDownloadLinks } from 'src/libs/downloadLinks';
import { getSubscriptions } from 'src/libs/payments';

import { userStore } from '.';

// const defaultSelectedSubscription = 'yearly3499'; // we disable all types of subscriptions except monthly1099
const defaultSelectedSubscription = 'monthly1099';

const getUserGeolocation = async () => {
    try {
        const res = await axios.get(getUserCountryUrl, { params: { zipCode: true } });

        onboardingStore.country = res?.data?.country?.toLowerCase() || 'zz';
        onboardingStore.city = res?.data?.city?.toLowerCase();
        onboardingStore.zipCode = res?.data?.zipCode;
        onboardingStore.ip = res?.data?.userIP?.toLowerCase();
    } catch (error) {
        //meh
    }
};

const onboardingStore = observable({
    loginType: 'signup',
    currentPosition: 0,
    isNewUser: true, //TODO maybe connect this with user store after we refactor all stores?
    subscriptionSelected: null,
    get hasHeader() {
        const placements = {
            0: true,
            1: true,
            2: false,
            3: false,
            4: false
        };
        return placements[this.currentPosition];
    },
    signInWithEmailLink: downloadLinkDesktop, //TODO change to null if we decide to run the autorun
    country: null,
    city: null,
    zipCode: null,
    ip: null,
    busy: false,
    downloadLinks: {}
});

// autorun(() => {
//     if (userStore.userDocument?.data?.signInWithEmailLink) {
//         onboardingStore.signInWithEmailLink = userStore.userDocument?.data?.signInWithEmailLink || downloadLinkDesktop;
//     }
// });

const setup = async () => {
    await getUserGeolocation();
    onboardingStore.downloadLinks = await getDownloadLinks();
};

setup();
when(
    () => {
        return onboardingStore.country;
    },
    async () => {
        userStore.payments.mainSubscriptions = await getSubscriptions();
        onboardingStore.subscriptionSelected = _.find(
            userStore.payments.mainSubscriptions,
            subscription => subscription.packageId === defaultSelectedSubscription
        );
    }
);

export { onboardingStore };
