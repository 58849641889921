import React from 'react';
import { Image } from 'react-native';

import { Column, Columns, useCurrentBreakpoint } from '@mobily/stacks';

import { Box } from '@mobily/stacks';
import { EmLink } from '../../components/EmLink/EmLink';

import { SocialButtons } from '../../components/SocialButtons/SocialButtons';

import { emjoywebUrl } from 'configs/conf';

import { images } from 'styles';

const HeaderWrapper = ({ children }) => {
    const currentBreakpoint = useCurrentBreakpoint();
    const mobile = currentBreakpoint === 'mobile';

    return (
        <>
            {mobile && (
                <Box paddingX={3} paddingTop={5} alignY={'bottom'}>
                    {children}
                </Box>
            )}
            {!mobile && (
                <Box paddingX={1} paddingTop={6} alignY={'bottom'} alignSelf={'stretch'}>
                    {children}
                </Box>
            )}
        </>
    );
};

const Header = ({ noSocial = false, logoColor = null }) => {
    const logoSize = {
        width: 96,
        height: 32
    };

    const currentBreakpoint = useCurrentBreakpoint();
    const mobile = currentBreakpoint === 'mobile';

    return (
        <HeaderWrapper>
            <Columns alignX={'between'} alignY={'center'}>
                <Column width={!mobile && !noSocial ? '1' : '1/2'}>
                    <EmLink target="_blank" rel="noindex, nofollow, noreferrer" href={emjoywebUrl}>
                        <Image source={images.logo_magenta} style={logoSize} />
                    </EmLink>
                </Column>

                {!mobile && !noSocial && (
                    <Column width="1/2">
                        <SocialButtons />
                    </Column>
                )}
            </Columns>
        </HeaderWrapper>
    );
};

export { Header };
