/* eslint-disable */
// import '@react-native-firebase/dynamic-links'; //TODO fix
import firebase from '@react-native-firebase/app';

import Config from 'react-native-config';
import parse from 'url-parse';

import { appStore } from '@stores';
import { callFirebaseFunction } from '@libs/callFirebaseFunction';
import { debug, debugConfigs } from '@emjoy/shared/configs';
import { L } from './i18n';

const getOrGenerateCode = async ({ referralType }) => {
    const { data } = await callFirebaseFunction('getOrGenerateCode', { referralType });
    return data;
};

const promoCodeErrors = error => {
    switch (error) {
        case 'only_valid_new_users':
            return L('promocode_only_valid_new_users');
        case 'campaign_not_active':
            return L('promocode_campaign_expired');
        case 'max_code_uses_reached':
            return L('promocode_max_code_uses_reached');
        case 'campaign_ended':
            return L('promocode_campaign_expired');
        case 'campaign_not_started':
            return L('promocode_campaign_not_started');
        case 'referrer_referree_has_same_id':
            return L('promocode_referrer_referree_has_same_id');
        default:
            return L('promocode_code_invalid_no_details');
    }
};

const generateReferralDynamicLink = async ({ referralType }) => {
    const code = await getOrGenerateCode({ referralType });
    const link = await firebase.dynamicLinks().buildShortLink(
        {
            link: `${Config.REFERRAL_DL_LINK_HTTPS}${code}`,
            domainUriPrefix: `${Config.REFERRAL_SHARE_APP_DOMAIN_PREFIX}`,
            android: {
                packageName: Config.APP_ID
            },
            ios: {
                bundleId: Config.APP_ID,
                appStoreId: Config.APPSTORE_ID
            }
        },
        'SHORT'
    );

    return { link, code };
};

const processReferralLink = link => {
    let code;
    if (link?.includes(Config.REFERRAL_DL_LINK)) {
        const { query } = parse(link, true);
        code = query.code;
    }

    debugConfigs.promoCode && debug.log('processReferralLink', { link, code });

    return code;
};

const updateReferral = async ({ code, isNewUser, target, event, onlyValidation, clearCode }) => {
    const { data, error } = await callFirebaseFunction('updateReferralV2', {
        code,
        isNewUser,
        target,
        event,
        onlyValidation
    });

    if (error) {
        return { error };
    }

    if (data) {
        if (clearCode) {
            appStore.launchReferralCodes = { type: null, code: null };
        }
        debugConfigs.promoCode && debug.log('updateReferralResp:', data);
        return data;
    }
};

export { generateReferralDynamicLink, processReferralLink, updateReferral };
