import React, { useEffect } from 'react';

import _ from 'lodash';
import { autorun, makeAutoObservable } from 'mobx';
import { Box } from '@mobily/stacks';

import { callFirebaseFunctionEndPoint } from 'src/libs/callFirebaseFunction';
import { ConclusionComponent, QuestionComponent, ResultComponent, WelcomeComponent } from './webQuizComponents';
import { EmImage } from '@emjoy/shared/primitives';
import { EmViewPager } from '@emjoy/shared/primitives/EmViewPager/EmViewPager';
import { getColor } from '@emjoy/shared/utils/getColor';
import { observer } from 'mobx-react';
import { styles } from './webQuiz.styles';
import { webViewStore } from 'src/screens/WebView/WebView';

//TODO this to functions
import surveyTests from '../../dataMockup/surveyTests';

class Quiz {
    constructor() {
        makeAutoObservable(this);
        this.getPercentages();
        if (this.userAnswers) {
            this.answers = webViewStore.userStore?.surveyData?.[this.testId]?.answers;
            this.currentPage = this.surveyTest.active ? 9 : 1;
            this.showPageDots = !this.surveyTest.active;
        }
    }

    //TODO get testID from app
    testId = 'sexToyQuiz';

    surveyTest = surveyTests[this.testId];
    currentPage = 0;
    answers = {};
    questions = this.surveyTest.questions;
    welcomeQuestion = this.surveyTest.welcomeQuestion;
    questions = this.surveyTest.questions;
    answerPercentages = {};
    userAnswers = webViewStore.userStore?.surveyData?.[this.testId]?.answers || null;
    showPageDots = true;

    get question() {
        return this.currentPage === 0
            ? this.surveyTest.welcomeQuestion
            : this.surveyTest.questions[this.currentPage - 1];
    }

    get totalPages() {
        //TODO make this dynamic with array size of pages in component
        return _.size(this.surveyTest.questions) + 3; // + 3 bc welcome + result + conclusion
    }

    get totalQuestions() {
        return _.size(this.surveyTest.questions);
    }

    get lastQuestion() {
        // welcome question counts as position 0 comparing
        // + 1 because saves after the last question
        return this.currentPage === _.size(this.surveyTest.questions) + 1;
    }

    get lastPage() {
        // return this.currentPage >= this.totalQuestions + 1;
        // fix to not show alert on close, fix when doing next quiz
        return true;
    }

    get onlyShowResults() {
        return !this.surveyTest.active || !!_.size(this.userAnswers);
    }

    questionAnswered = questionId => {
        if (this.surveyTest.active === false) {
            return true;
        }
        return !!this.answers[questionId];
    };

    get sumPoints() {
        return _.reduce(
            this.answers,
            (n, answer) => {
                return answer.points + n;
            },
            0
        );
    }

    get conclusion() {
        return _.find(this.surveyTest.conclusions, conclusion => conclusion.maxPoints > this.sumPoints);
    }

    setNextPage = () => {
        this.currentPage++;
        if (this.lastQuestion) {
            this.sendSuveyAnswersToApp();
        }
    };

    setAnswer = option => {
        this.answers[this.question.id] = { id: option.id, points: option.points };
    };

    sendSuveyAnswersToApp = () => {
        if (this.surveyTest.active) {
            webViewStore.sendObjectToApp({
                surveyTestAnswers: { testId: this.testId, answers: this.answers, points: this.sumPoints }
            });
        }
    };

    getPercentages = async () => {
        const { data } = await callFirebaseFunctionEndPoint('/getSurveyTestResult/' + this.testId);
        if (data) {
            this.answerPercentages = data;
        }
    };
}

const webQuizStore = new Quiz();

autorun(() => {
    webViewStore.sendObjectToApp({ currentPage: webQuizStore.currentPage, lastPage: webQuizStore.lastPage });
});

const WebQuiz = observer(() => {
    const { question, currentPage, welcomeQuestion, questions, surveyTest, totalPages, showPageDots } = webQuizStore;

    useEffect(() => {
        webViewStore.sendObjectToApp({ colorScheme: question?.colorScheme || 'light' });
    }, [question]);

    const pages = [
        <WelcomeComponent welcomeQuestion={welcomeQuestion} />,
        ...webQuizStore.questions.map((q, i) => <QuestionComponent question={questions[i]} />),
        <ConclusionComponent />,
        <ResultComponent />
    ];

    return (
        <Box
            flex={'fluid'}
            alignX={'center'}
            alignY={'center'}
            style={{
                backgroundColor: getColor(question?.color) || getColor('white')
            }}
        >
            {currentPage === 0 && (
                <>
                    <EmImage source={surveyTest.backgroundTopImage} style={styles.backgroundTopImage} />
                    <EmImage source={surveyTest.backgroundBottomImage} style={styles.backgroundBottomImage} />
                </>
            )}
            <EmViewPager
                controller={webQuizStore}
                dotsWrapperProps={showPageDots && { dataLength: totalPages, currentIndex: currentPage }}
            >
                {pages}
            </EmViewPager>
        </Box>
    );
});

export { WebQuiz, webQuizStore };
