import React, { useEffect, useRef } from 'react';

import _ from 'lodash';
import Swiper from 'react-native-web-swiper';
import { Box } from '@mobily/stacks';
import { DotsWrapper } from '@emjoy/shared/components/DotsWrapper/DotsWrapper';

import { styles } from './EmViewPager.styles';

const EmViewPager = ({ children, controller, dotsWrapperProps, ...rest }) => {
    const swiperRef = useRef(null);

    const { currentPage } = controller;

    useEffect(() => {
        swiperRef.current.goTo(currentPage);
    }, [currentPage]);

    return (
        <Box style={styles.webContainer} {...rest}>
            {dotsWrapperProps && (
                <Box style={styles.webDotsWrapperContainer} alignX={'center'} alignY={'center'}>
                    <DotsWrapper dataLength={10} currentIndex={2} dontShowFirst dontShowLast {...dotsWrapperProps} />
                </Box>
            )}
            <Swiper ref={swiperRef} minDistanceForAction={100} controlsEnabled={false} gesturesEnabled={() => false}>
                {_.map(children, (child, index) => (
                    <Box key={index} flex={'fluid'} alignX={'center'} alignY={'center'}>
                        {child}
                    </Box>
                ))}
            </Swiper>
        </Box>
    );
};

export { EmViewPager };
