import _ from 'lodash';
import React, { Component } from 'react';
import { Dimensions, Text } from 'react-native';

import Svg, { ClipPath, Defs, G, Image, LinearGradient, Path, Rect, Stop } from 'react-native-svg';
import { observer } from 'mobx-react';

import { Box } from '@mobily/stacks';

import { EmButton, EmInput, EmText } from '@emjoy/shared/primitives';

import styles, { h, scale, w } from 'components/utils/svgGradients/svgGradients.styles';

import { colors } from '@emjoy/shared/styles';
import { svgPaths } from 'styles';
import { Toolbar } from 'components/Toolbar/Toolbar';

let { width } = Dimensions.get('window');

//TODO to functional
const SvgHeader = observer(
    class SvgHeader extends Component {
        onClose = () => {
            this.props.onClose?.();
        };

        render() {
            const scaleUsed = this.props.isMobile ? width / 375 : scale;

            const shape = this.props.shape ? this.props.shape : svgPaths.headerCurveOne;
            const height = Math.floor(this.props.height || h);

            const curveStarts = height - shape.curveStartDifference;
            const paddingBottom = scaleUsed >= 1 ? Math.floor(60 * scaleUsed) : 0;

            const hasGradient = !this.props.image || this.props.hasGradient;

            const path = shape.d.replace('{curveStarts}', curveStarts);

            const svgGradientId = _.uniqueId('gradient-');
            const svgHeaderGradientId = _.uniqueId('gradientHeader-');

            return (
                <Box style={[styles.headerWrapper, { height: height, paddingBottom: paddingBottom }, this.props.style]}>
                    <Box style={styles.positionAbsolute}>
                        <Svg width={w * scaleUsed} height={height}>
                            <Defs>
                                {hasGradient && (
                                    <LinearGradient id={svgGradientId} x1="0%" y1="87.572%" y2="87.572%">
                                        <Stop offset="0%" stopColor={colors.primary.default} />
                                        <Stop offset="100%" stopColor={colors.primary.default} />
                                    </LinearGradient>
                                )}
                                {this.props.image && (
                                    <>
                                        <ClipPath id="clip">
                                            <Path d={path} transform={`scale(${scaleUsed} 1)`} />
                                        </ClipPath>

                                        <LinearGradient id={svgHeaderGradientId} x1="0%" x2="50%" y1="1" y2="0">
                                            <Stop offset="0%" stopColor={colors.black} stopOpacity={0.5} />
                                            <Stop offset="100%" stopColor={colors.black} stopOpacity={0} />
                                        </LinearGradient>
                                    </>
                                )}
                            </Defs>

                            {this.props.image && (
                                <>
                                    <G clipPath="url(#clip)">
                                        <Rect width={w} height={height} fill={this.props.backgroundColor} />

                                        <Image
                                            width={w}
                                            height={height}
                                            preserveAspectRatio="xMidYMin slice"
                                            href={this.props.image}
                                        />

                                        {!this.props.noImageGradient && (
                                            <Path
                                                fill={`url(#${svgHeaderGradientId})`}
                                                fillRule="evenodd"
                                                d={path}
                                                transform={`scale(${scaleUsed} 1)`}
                                            />
                                        )}
                                    </G>
                                </>
                            )}

                            {hasGradient && (
                                <Path
                                    fill={`url(#${svgGradientId})`}
                                    fillRule="evenodd"
                                    d={path}
                                    transform={`scale(${scaleUsed} 1)`}
                                />
                            )}
                        </Svg>
                    </Box>

                    <Box>
                        {this.props.title && (
                            <EmText
                                type={'h2'}
                                color={'white'}
                                style={this.props.titleStyle}
                                boxProps={{ marginX: 3, marginTop: 3 }}
                            >
                                {this.props.title}
                            </EmText>
                        )}
                        {this.props.subtitle && (
                            <Text style={[styles.pageSubtitleTitle, this.props.subtitleStyle]}>
                                {this.props.subtitle}
                            </Text>
                        )}
                        {this.props.children}
                        {this.props.hasClose && (
                            <Toolbar
                                hasClose
                                onClose={this.onClose}
                                style={
                                    this.props.hasTopPadding
                                        ? styles.svgHeaderToolbarTopPadding
                                        : styles.svgHeaderToolbar
                                }
                                closeColor={colors.white}
                            />
                        )}
                    </Box>
                </Box>
            );
        }
    }
);

export { SvgHeader };
