const baseUrl = 'https://app.letsemjoy.com';

const emjoywebUrl = 'https://letsemjoy.com';
const sessionImageUrl = 'https://storage.googleapis.com/emjoy-cdn/sessions/{sessionId}/thumbs/{image}';
const categoriesImageUrl = 'https://storage.googleapis.com/emjoy-cdn/categories/{categoryId}/thumbs/{image}';
const sessionDataUrl = 'https://europe-west1-emjoyproduction.cloudfunctions.net/dataAPI/session/{sessionId}';
const allSessionsUrl = 'https://europe-west1-emjoyproduction.cloudfunctions.net/dataAPI/session/ids';
const functionBaseUrl = 'https://europe-west1-emjoyproduction.cloudfunctions.net';

const downloadLinkDesktop =
    'https://b2pr.adj.st/deeplink/today/?adj_t=cs68v18&adj_campaign=WebApp_SignInEmail_AllOS_Download&adj_fallback=https://app.letsemjoy.com/';

const signInEmailFallbackUrl = 'https://letsemjoy.com/download';
const appIosBundle = 'com.letsemjoy.app';
const appAndroidBundle = 'com.letsemjoy.app';
const signInEmailAndroidMinVersion = '2.9.0';
const signInEmailDynamicLinkDomain = 'join.letsemjoy.com';

const ppUrl = 'https://www.iubenda.com/privacy-policy/75058156/full-legal';
const tosUrl = 'https://www.letsemjoy.com/terms-and-conditions';

const getUserCountryUrl = 'https://europe-west1-emjoyproduction.cloudfunctions.net/ip';

const GA_TRACKING_ID = 'UA-148933530-1';

const stripeApiKey =
    'pk_live_51HZdX2Jxx7mFaucIwAPB0WjbHDoP9o14s0xcrtnPfmvz48nd23n9gZcX31lS7Gagl7La4S6DpPavV3Cn5yIspn2X000vLj4krj'; //TODO move to conf
const revenueCatApiKey = 'sk_WpLBUVYGSsbNPTZENWnOxtdwzmvKy'; //TODO move to conf

export {
    baseUrl,
    categoriesImageUrl,
    sessionImageUrl,
    emjoywebUrl,
    GA_TRACKING_ID,
    sessionDataUrl,
    allSessionsUrl,
    signInEmailFallbackUrl,
    appAndroidBundle,
    appIosBundle,
    signInEmailAndroidMinVersion,
    signInEmailDynamicLinkDomain,
    downloadLinkDesktop,
    stripeApiKey,
    functionBaseUrl,
    ppUrl,
    tosUrl,
    getUserCountryUrl,
    revenueCatApiKey
};
