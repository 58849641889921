import { StyleSheet } from 'react-native';

import { colors } from '@emjoy/shared/styles';
import { fontSizes, sizes, textStyle } from 'styles';

sizes.screenWidth = 375;
sizes.statusBarHeight = 20;

const DEFAULT_HEIGHT = 140 + sizes.statusBarHeight;

export const w = sizes.screenWidth;
export const h = Math.ceil((DEFAULT_HEIGHT / 375) * sizes.screenWidth);
export const scale = sizes.screenWidth / 375;

const style = StyleSheet.create({
    headerWrapper: {
        width: '100%',
        backgroundColor: 'transparent'
    },
    pageTitle: {
        ...textStyle.headlineBold,
        textAlign: 'left',
        color: colors.white,
        marginHorizontal: sizes.dim3x,
        letterSpacing: 0.3,
        marginTop: sizes.statusBarHeight + sizes.dim1x
    },
    pageSubtitleTitle: {
        ...textStyle.titleBold,
        fontSize: fontSizes.size4,
        textAlign: 'left',
        color: colors.white,
        marginHorizontal: scale >= 1 ? sizes.dim4x : sizes.dim2x,
        marginTop: sizes.statusBarHeight,
        marginBottom: sizes.dim2x
    },
    container: {
        width: '100%',
        justifyContent: 'center'
    },
    positionAbsolute: {
        position: 'absolute'
    },
    svgPosition: {
        position: 'absolute',
        top: 0
    },
    svgHeaderToolbar: { top: 0, position: 'absolute' },
    svgHeaderToolbarTopPadding: { top: sizes.statusBarHeight, position: 'absolute' }
});

export default style;
