import axios from 'axios';

const getUrl = async (url, config) => {
    try {
        const { data } = await axios.get(url, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
                ...config
            }
        });
        return { data };
    } catch (error) {
        return { error };
    }
};

export { getUrl };
