import { StyleSheet } from 'react-native';

import { colors } from '@emjoy/shared/styles';

const style = StyleSheet.create({
    container: {
        width: '100%',
        overflow: 'hidden',
        backgroundColor: colors.white
    }
});

export default style;
